import React, {
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { AnswerModel } from '../../model/answer_model';
import {
  Item3,
  MarksMap2,
} from '../../model/test_model';

const QuestionLs = (props: any) => {
  var data: Item3 = props.data;
  const [selectedSlider, setSelectedSlider] = useState(""); 
  const [positionLeft, setPositionLeft] = useState(0); 
  const [positionRight, setPositionRight] = useState(0); 
  const { t } = useTranslation();

  const setposition = (side: string, value: number) => {
    if (side === "left") {
      setSelectedSlider("left");
      setPositionLeft(value);
      setPositionRight(0);
    } else if (side === "right") {
      setSelectedSlider("right");
      setPositionRight(value);
      setPositionLeft(0); 
    }

    // Prepare and send answer model
    if (side !== "" && value > 0) {
      const answerModel = new AnswerModel({
        questionCode: data.questionCode,
        isMarked: false,
        answerData: {
          alphaData: side,
          numericData: value,
        },
      });
      props.onAnswer(answerModel);
    }
  };

  useEffect(() => {
    debugger;
    // Set the selected slider and its position from the data
    if (data["answer"] && data["answer"]["answerData"] && data["answer"]["answerData"]["numericData"]) {
      const alphaData = data["answer"]["answerData"]["alphaData"];
      const numericData = data["answer"]["answerData"]["numericData"];
      setSelectedSlider(alphaData);
      if (alphaData === "left") {
        setPositionLeft(numericData);
      } else if (alphaData === "right") {
        setPositionRight(numericData);
      }
    }
  }, []);

  const marks = [];

  if (data && data.choices && data.choices.length >= 2) {
    marks.push({
      value: 0,
      label: (
        <span dangerouslySetInnerHTML={{ __html: data.choices[0].choice}} />
      ),
    });
    marks.push({
      value: 100,
      label: (
        <span dangerouslySetInnerHTML={{ __html: data.choices[1].choice }} />
      ),
    });
  } else {
  }
  
  
  function valuetext(value: number) {
    return `${value}`;
  }

  return (
    <div>
      {data["showPosition"] && data.questionNumber && <span className="inline mt-1">{data.questionNumber}.&nbsp;</span>}
      {data.renderedQuestion}
      {/* <span
        className="inline pb-5"
        dangerouslySetInnerHTML={{ __html: data.question }}
      ></span> */}
      <div className="grid grid-cols-2 mt-5 mr-10">
        <div key="QUS-1" className="pr-5">
          <div onChange={() => setposition("left", 0)} className="flex">
          </div>
          <div className="ml-7 mt-10">
            <Box sx={{ width: 600 }}>
              <Slider
                aria-label="Restricted values"
                value={selectedSlider === "left" ? positionLeft : 0}
                defaultValue={0}
                getAriaValueText={valuetext}
                step={marks.length>2?null:data.scaleMax} 
                valueLabelDisplay="auto"
                marks={marks}
                sx={{
                  width: "100%",
                  height: "30%",
                  backgroundImage:
                    selectedSlider === "left"
                      ? `linear-gradient(90deg, #ff9800 ${
                          (positionLeft / data.scaleMax) * 100
                        }%, transparent 0%)`
                      : "",
                  borderRadius: "8px",
                  color: "#ff9800",
                }}
                onChange={(event, value) => {
                  const sliderValue = Array.isArray(value) ? value[0] : value;
                  setposition("left", sliderValue);
                }}
                max={data.scaleMax}
                min={data.scaleMin}
                className={`w-full h-2 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 ${
                  selectedSlider !== "" && data.highlight === "tutorial"
                    ? "shadow_animation"
                    : ""
                }`}
              />
            </Box>
          </div>
          <div className="max-w-full text-xs font-semibold flex flex-wrap items-center justify-between mx-2 py-2">
            {data.marksMap?.map((ele: MarksMap2, index: number) => (
              <span
                key={`OPTR-${index}`}
                dangerouslySetInnerHTML={{ __html: ele.label }}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default QuestionLs;
