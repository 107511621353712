import React, {
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { AnswerModel } from '../../model/answer_model';
import { Item4 } from '../../model/test_model';

const QuestionListening = (props: any) => {
  const { t } = useTranslation();
  var data: Item4 = props.data;
  const [selectedOption, setSelectedOption] = useState("");
  const [attempt, setAttempt] = useState(0); 
  const [playing, setPlaying] = useState(false);

  const handleOptionSelect = (option: any) => {
    setSelectedOption(option);
    const answerModel = new AnswerModel({
      questionCode: data.questionCode,
      answerId: option,
      maxRetry:attempt,
    });
    props.onAnswer(answerModel);
  };

  useEffect(() => {
    console.log(data);
    if (data["answer"] && data["answer"]["answerId"]) { 
      setSelectedOption(data["answer"]["answerId"]);
    }
    if (data["answer"] && data["answer"]["maxRetry"]){
      setAttempt(data["answer"]["maxRetry"]);
    }
  }, []);

  const DataAudio = data.question.match(/{{{audio\/.{1,10}}}}/g);

  const AudioSrc = DataAudio?.map((audio) => {
    return audio.replace(
      /{{{audio\/(LISTEN\d+)}}}/g,
      "https://nxbas-prod.sgp1.cdn.digitaloceanspaces.com/question-assets/$1.mp3"
    );
  });

  return (
    <div>
      <div className="audio-info"></div>
      <div className="mcq-questions flex justify-between ">
        <div key={data.questionCode}>
        {data["showPosition"] && data.questionNumber && <span className="inline mt-1">{data.questionNumber}.&nbsp;</span>}
          {data.renderedQuestion}
          {/* {data.question.split(/{{{audio\/[A-Z0-9]+}}}/).map((part, index) => (
            <React.Fragment key={index}>
              {index > 0 && (<div>
                <audio 
                  controls
                  controlsList="nodownload" 
                  className="w-full" 
                  style={{pointerEvents: attempt<4 && !playing?"all":"none"}}
                  onPlay={()=>{
                    props.onAnswer({
                      maxRetry:attempt+1,
                      answerId: selectedOption,
                      questionCode: data.questionCode,
                    });
                    setAttempt(attempt+1);
                    setPlaying(true);
                  }}
                  onEnded={()=>{
                    setPlaying(false);
                  }}
                >
                  {AudioSrc && AudioSrc.map((src, index) => (
                    <source key={index} src={src} type="audio/mpeg" />
                  ))}
                </audio>
                <p className="mt-1 text-sm text-gray-500">{t("remaining_attempts",{count:4-attempt})}</p>              
              </div>
              )}
              <span
                dangerouslySetInnerHTML={{ __html: part }}
                className="inline"
              ></span>
            </React.Fragment>
          ))} */}

          <ul className="mt-5">
            {data.choices.map((choice: any) => (
              <li key={choice.id}>
                <input
                  type="radio"
                  id={choice.id}
                  className="p-1 my-2"
                  name={`question-${data.questionCode}`}
                  checked={selectedOption === choice.id}
                  onChange={() => handleOptionSelect(choice.id)}
                />
                <label className="pl-2 text-gray-800" htmlFor={choice.id}>
                  {choice.choice}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default QuestionListening;
