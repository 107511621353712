class AnswerModel {
    targetQuestionPosition!: number;
    testTakerId!: number;
    sectionNumber!: number;
    questionPosition!: number;
    questionCode!: string;
    questionType!: string;
    spendTime!: number;
    answerId?: string = "";
    isAnswered: boolean = true;
    maxRetry: number = 0;
    isMarked: boolean = false;
    freeTextResponse: any;

    answerData!: {
        numericData?: number;
        subData?: {
            position?: number;
            questionCode?: string;
            answerCode?: string;
            extraParam?: string;
        }[];
        alphaData?: string;
    };
    

    constructor(props?: Partial<AnswerModel>, answerCode?: string) {
        Object.assign(this, props);
        if (answerCode) {
            this.answerData = {
                numericData: 0,
                subData: [{
                    position: 0,
                    questionCode: props?.questionCode || "", 
                    answerCode: answerCode
                }],
                alphaData: ''
            };
        }
    }
}
export { AnswerModel };