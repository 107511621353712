/* eslint-disable react-hooks/exhaustive-deps */
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { BsMicFill } from 'react-icons/bs';

import { UserContext } from '../../context_provider/user_provider';
import endpoints from '../../data/network/API_ENDPOINT';
import HTTP from '../../data/network/HTTP';
import { AnswerModel } from '../../model/answer_model';
import { Item4 } from '../../model/test_model';

const QuestionSpeakingV2 = (props: any) => {
  const { t } = useTranslation();
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [recording, setRecording] = useState<MediaRecorder | null>(null);
  const [timer, setTimer] = useState<number>(0);
  const [recordState, setRecordState] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);
  var data: Item4 = props.data; 
  const[, setAudioAns] =useState("")
  const ctx: any = useContext(UserContext);
  const [attempts, setAttempts] = useState(0);
  const permissionStates = { 
    dismissed:"dismissed",
    denied:"denied",
    allow:"allow"
  };
  const [hasPermission, setHasPermission] = useState(permissionStates.allow);

  useEffect(()=>{
    try {
      if(props.data.answer.isAnswered){
        setRecordState(3);
      }
      setAttempts(props.data.answer.maxRetry??0);
      if(props.data.answer){
      }
    } catch (error) {}

    navigator.mediaDevices.getUserMedia({audio:true,preferCurrentTab:true,video:true})
    .then((permissionObj) => {
      setHasPermission(permissionObj.active?permissionStates.allow:permissionStates.denied);
    }) 
    .catch((error) => {
      console.error(error);
      if(error.toString().indexOf(permissionStates.dismissed)>=0){
        setHasPermission(permissionStates.dismissed);
      }else{
        setHasPermission(permissionStates.denied);
      }
    });

    try {
      audioRef.current?.setAttribute("src",`${HTTP.API_URL}test-taking/get-file/audio/${props.data.answer.answerData.alphaData}`);
      audioRef.current?.load();
      setAudioUrl(`${HTTP.API_URL}test-taking/get-file/audio/${props.data.answer.answerData.alphaData}`);
    } catch (error) {
    }
  },[])

  useEffect(()=>{
    if(recording){
      setTimeout(()=>{setTimer(timer+1)},1000)
    }
    return ()=>{}
  },[timer,recording])

  const handleStartRecording = async () => {
    try {
      setRecordState(2);
      setTimer(0);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true});
      const recorder = new MediaRecorder(stream);
      const chunks: BlobPart[] = [];
      recorder.ondataavailable = (e) => chunks.push(e.data);
      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' });
        const url = URL.createObjectURL(blob);
        submitAudio(url);
        setAudioUrl(url);
      };
      recorder.start();
      setRecording(recorder);
      setAttempts(attempts+1);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };
  
  const handleStopRecording = () => {
    if (recording) {
      recording.stop();
      setRecording(null);
    } 
    setRecordState(3);
  };

  const handleSubmitRecording = (audioAns: any ) => {
    let answerModel = null;
    answerModel = new AnswerModel({
      maxRetry: attempts+1,
      questionCode: data.questionCode,
      answerData:{alphaData:audioAns}
    });
    props.onAnswer(answerModel);
  };
  
  const submitAudio = (recordData:any) => {
    if (recordData) {
      fetch(recordData)
        .then((res) => res.blob())
        .then((blob) => {
          const audioFile = new File([blob], 'audio.wav', { type: 'audio/wav' });
          var formData = new FormData();
          formData.append("file", audioFile);
          formData.append("testTakerId",ctx.testTaker["id"]);
          formData.append("questionId",`${data.questionNumber}`);
          fetch(HTTP.API_URL + endpoints.submitAudio, {
            method: 'POST',
            body: formData,
            headers: {"Authorization": `Bearer ${sessionStorage.getItem("token")}`},
          })
          .then(r => r.text())
          .then((data) => {
            setAudioAns(data);
            handleSubmitRecording(data);
          })
        })
        .catch((error) => {
          console.error("Error fetching audio data:", error);
        });
    }
  }
  
  return (
    <div>
      {/* md:h-[60vw] max-h-[70vh] */}
      <div className="shadow-sm bg-gray-50 p-3 rounded-md hcenter"> 
        <div className="bg-white p-5 rounded-md  hleft">
          {data["showPosition"] && data.questionNumber && <span className="inline mt-1">{data.questionNumber}.&nbsp;</span>}
          {data.renderedQuestion}
        </div>
        <br/>
        {
        (recordState===0) && 
          <button className="border rounded-md bg-blue px-8 py-2 border-blue-800 mt-10" onClick={()=>{setRecordState(data.validationCondition.max_retries===attempts?3:1)}}>
            <span className='flex items-center'><BsMicFill className="text-orange-500"/> &nbsp; {ctx.t("proceed_record")} </span>
          </button>
        }
      </div>
      {
      recordState===1?
        <div className="hcenter"> 
          <Timer total={props.data.validationCondition.preparation_time} onComplete={()=>{handleStartRecording();}}></Timer>
          <p className="font-bold">{ctx.t("prepare_time")}</p>
        </div>
      :recordState===2?
        <div className="hcenter">
          {
            hasPermission!==permissionStates.allow?
            <div className="text-sm mt-5">
              {
                hasPermission===permissionStates.dismissed?
                <div className="my-20">
                  <p>{t("permission_dissmiss")}</p>
                  <button 
                    className="bg-gray-400 text-white rounded-md py-1.5 px-4 mt-3"
                    onClick={()=>{
                      navigator.mediaDevices.getUserMedia({video:true}).then(r=>{}).catch(r=>{});
                    }}
                  >{t("try_again")}</button>
                </div>
                : <p className="my-20 text-red-500">{t("permission_disable")}</p>
              }
            </div>:
          (<><RecordingTimer total={props.data.validationCondition.max_response_time} onComplete={()=>{handleStopRecording()}}></RecordingTimer>
          <p className="font-bold">{ctx.t("recording_now")}</p></>)
          }
        </div>
      :audioUrl? 
        <div className="flex justify-center my-16  p-10 rounded-md">
          <div>
            <audio
              controlsList="nodownload"
              ref={audioRef}
              src={audioUrl || undefined}
              key={`${audioUrl?.replace(/ /g,"")}`}
              controls
              className="sm:w-[750px] md:w-[500px] sm:mb-2"
            />
            {
              (data.validationCondition.max_retries-attempts)>0?
              <div className="flex justify-center mt-5">
                <p className="mt-1 text-sm text-gray-500">{t(`remaining_attempt${(data.validationCondition.max_retries-attempts)>1?'s':''}`,{count:t(`${data.validationCondition.max_retries-attempts}`)})}</p>
                {(recordState===3) &&
                <button className="ml-3 px-4 py-1 border border-blue-500 rounded-md text-blue-500" onClick={()=>{setRecordState(0)}}>{ctx.t("record_again")}</button>}
              </div>:<></>
            }
          </div>
        </div>
        :<></>
      }
    </div>
  );
};


const RecordingTimer = ({total=120,onComplete=()=>{}}) => {
  const [seconds, setSeconds] = useState(total);

  useEffect(() => {
    if(seconds>0){
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
    if(seconds===0){
      onComplete();
    }
  }, [seconds]);

  const formatTime = (time:any) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `<span id="minutes">${minutes < 10 ? '0' : ''}${minutes}</span>:<span id="seconds">${seconds < 10 ? '0' : ''}${seconds}</span>`;
  };

  return (
    <div className="timer">
      <div className="relative inline-flex items-center justify-center w-48 h-48 my-10 bg-gray-50 rounded-full">
            <svg className="absolute inset-0 w-full h-full transform -rotate-90">
              <circle
                cx="50%"
                cy="50%"
                r="70"
                stroke={seconds<(total/3)? "red":seconds<(total/2)? "orange": "currentColor"}
                className="text-blue-500"
                stroke-width="10"
                fill="none"
                stroke-dasharray={440}
                stroke-dashoffset={(total-seconds)*(440/total)}
                id="timer-circle"
              />
            </svg>
            <div className="">
              <BsMicFill className="w-full text-5xl text-orange-400 mb-2"/>
              <span 
              style={{ color: seconds<30? "red":seconds<60? "orange": "currentColor" }}
              dangerouslySetInnerHTML={{__html:formatTime(seconds)}} className="text-2xl" id="timer"></span>
            </div>
        </div>
    </div>
  );
};


const Timer = ({total=120,onComplete=()=>{}}) => {
  const [seconds, setSeconds] = useState(total);

  useEffect(() => {
    if(seconds>0){
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
    if(seconds===0){
      onComplete();
    }
  }, [seconds]);

  const formatTime = (time:any) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `<span id="minutes">${minutes < 10 ? '0' : ''}${minutes}</span>:<span id="seconds">${seconds < 10 ? '0' : ''}${seconds}</span>`;
  };

  return (
    <div className="timer">
      <div className="relative inline-flex items-center justify-center w-48 h-48 my-10">
            <svg className="absolute inset-0 w-full h-full transform -rotate-90">
                <circle
                    cx="50%"
                    cy="50%"
                    r="70"
                    stroke={seconds<(total/3)? "red":seconds<(total/2)? "orange": "currentColor"}
                    className="text-blue-500"
                    stroke-width="10"
                    fill="none"
                    stroke-dasharray={440}
                    stroke-dashoffset={(total-seconds)*(440/total)}
                    id="timer-circle"
                />
            </svg>
            <span 
            style={{ color: seconds<30? "red":seconds<60? "orange": "currentColor" }}
            dangerouslySetInnerHTML={{__html:formatTime(seconds)}} className="text-4xl" id="timer"></span>
        </div>
    </div>
  );
};

export default QuestionSpeakingV2;
