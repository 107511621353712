import { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../../context_provider/user_provider';
import routingPath from '../../../routing/router_path';

function DemographicFinish() {
    const navigate = useNavigate();
    const ctx:any = useContext(UserContext);
    const { t } = useTranslation();

    function finishDemographic(event:any):void {
        ctx.setQPosition(0);
        ctx.setQSection(0);
        navigate(routingPath.verificationUser);
    }

    return (
        <div className="">
            <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
                <div className="max-w-full flex flex-wrap items-center justify-between mx-auto p-4">
                    <img src="/images/nxbas.avif" className="h-14" alt="NxBAS Logo" />
                    <p className="text-sm sm:mr-6 uppercase">ID : {ctx.user['username']}</p>
                </div>
            </nav>

            <hr/><br/><br/><br/><br/>

            <div className="w-full text-center mt-36">
                <span dangerouslySetInnerHTML={{__html:ctx.test['endOfTestDemographicMessage']}} ></span>
                {/* <p className="text-2xl font-normal text-black">{t("dem_comp_desc")}</p> */}
                <button onClick={finishDemographic} 
                className="m-5 bg-blue-500 text-white p-2 px-4 rounded-md uppercase">{t("next")}</button>
            </div>
        </div>
    );

}
export default DemographicFinish;