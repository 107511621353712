import routingPath from '../../routing/router_path';

const routingQuestionDecision = (test:any, testTaker:any) => {
    let routingArray:any = [];
    console.log("Test & Test-taker >> ",test,testTaker);
    if(testTaker.status==="ANSWERING"){
        routingArray.push(routingPath.testTakingInstruction);
        return routingArray;
    }
    if(!test.dpaDisabled && !testTaker.dpaAccepted){
        routingArray.push(routingPath.dpa);
    }
    if(!test.tutorialDisabled && test.tutorialSections!=null && test.tutorialSections.length>0){
        routingArray.push(routingPath.tutorialInstruction);
    }
    if(test.demographicSection && test.demographicSection.items.length>0){
        if(testTaker.demographicSectionCompleted)
            routingArray=[];
        else
            routingArray.push(routingPath.demographicQuestion);
    }
    if(test.cameraRequired && !testTaker.userPhotoUploaded){
        routingArray.push(routingPath.verificationUser);
    }
    routingArray.push(routingPath.testTakingInstruction);
    return routingArray;
}
export { routingQuestionDecision };