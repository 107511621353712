import {
  useContext,
  useEffect,
} from 'react';

import { useTranslation } from 'react-i18next';

import { logoutButton } from '../../../components/common/page_components';
import { UserContext } from '../../../context_provider/user_provider';

function TestTakingFinish() {
  const ctx: any = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {}, []); 

  return (
    <div className="">
      <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
        <div className="max-w-full flex flex-wrap items-center justify-between mx-auto p-4">
          <img src="/images/nxbas.avif" className="h-14" alt="NxBAS Logo" />
          <p className="text-sm sm:mr-6 uppercase">
            ID : {ctx.user["username"]}
          </p>
        </div>
      </nav>
      <hr />
      <br />
      <br />
      <br />
      <br />
      <div className="w-full text-center mt-36">
        <div className="text-lg text-center">
          <span dangerouslySetInnerHTML={{__html:ctx.test['endOfTestMessage']}} ></span>
          {/* <p>{t("complete_test")}</p> */}
          {/* <p>{t("thank_you")}</p> */}
        </div>
        <br />
        <br />
        <div>
        <div className="mb-5">{logoutButton(ctx)}</div>
       {/* <div className="mb-5">
            <a 
              href={process.env.PUBLIC_URL + "/score_demo.pdf"}
              download
            className="focus:outline-none text-white bg-green-500 hover:bg-green-700 font-medium rounded-md text-sm px-2 py-2.5">{ctx.translate("download_report")}</a>
        </div>*/}

        </div>
      </div>
    </div>
  );
}

export default TestTakingFinish;
