import React, {useEffect, useState} from 'react';

import { useTranslation } from 'react-i18next';
import {
    BsFillInfoCircleFill,
    BsChevronDown,
    BsChevronUp,
    BsChevronExpand,
    BsClipboardPlus,
    BsClipboardMinus, BsPlusSlashMinus, BsPlus, BsArrowsAngleExpand, BsArrowsCollapse, BsArrowUp, BsDash
} from 'react-icons/bs';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import {initFlowbite} from "flowbite";

interface SideBarGlossaryProps {
    value: boolean;
    setValue: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideBarGlossary: React.FC<SideBarGlossaryProps> =  ({ value: glossary, setValue: setGlossary }) => {
    const [isHorizontal, setIsHorizontal] = useState(false);
    const { t } = useTranslation();
    const glossaryContent = t("glossary", { returnObjects: true }) as Array<{ title: string, content: string }>;
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const toggleAccordion = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    useEffect(()=>{
        initFlowbite();
    },[])

    return (
        <div>
            <button className="absolute right-0 bottom-1 bg-blue-500 p-2 rounded-tl-lg rounded-bl-lg z-20" onClick={() => { setGlossary(!glossary);setIsHorizontal(!isHorizontal); }} style={{borderTopLeftRadius:10}}>
                <BsFillInfoCircleFill size={30} color='white'></BsFillInfoCircleFill>
            </button>
            {
                glossary ?
                    <div className="bg-white w-full md:w-96 p-2 md:p-8 shadow-lg border-2 border-green-500 w-200 h-80 overflow-x-scroll overflow-y-scroll mr-12 rounded-lg" style={{}}>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">Title</th>
                                    <th scope="col" className="px-6 py-3">Content</th>
                                </tr>
                                </thead>
                                <tbody>
                                {glossaryContent.map((item, index) => (
                                    <tr key={index} className={`odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700`}>
                                        <td className="px-6 py-4">
                                            {t("glossary", { returnObjects: true })[index]['title']}
                                        </td>
                                        <td className="px-6 py-4">
                                            {t("glossary", { returnObjects: true })[index]['content']}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                    </div> : <></>

            }
        </div>
    );
}

export { SideBarGlossary };
