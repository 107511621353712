class ErrorModel {
    message!:string;
    type!:ErrorTypes;

    constructor(props?: Partial<ErrorModel>) {
        Object.assign(this, props);
    }
}

enum ErrorTypes {
    Warning,
    Error,
    Exception
}

export { ErrorModel, ErrorTypes };