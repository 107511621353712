import React, {
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { AnswerModel } from '../../model/answer_model';
import { Item3 } from '../../model/test_model';

const QuestionClozeTable = (props: any) => {
  const { t } = useTranslation();
  var data: Item3 = props.data;
  const [answer, setAnswer] = useState<any>({});
  const [udpate, setUdpate] = useState(0);

  useEffect(() => {
    if(data['answer'] && data['answer']['answerData'] && data['answer']['answerData']['subData']){
      data['answer']['answerData']['subData'].forEach((answerItem:any) => {
        answer[answerItem['questionCode']] = answerItem;
      });
      setAnswer(answer);
      setUdpate(Date.now());
    } 
  }, []);

  useEffect(()=>{
    const answerModel = new AnswerModel({
      questionCode: data.questionCode,
      isMarked: data.lastQuestionInSection?
        Object.values(answer).some((e:any) => e['extraParam'] === "true"):
        Object.values(answer).every((e:any) => e['extraParam'] === "true" || e['answerCode'] !== ""),
      isAnswered: Object.values(answer).every((e:any) => e['answerCode'] !== ""),
      answerData: {subData: Object.values(answer)}
    });
    console.log("Current answer : ",answerModel);
    props.onAnswer(answerModel);
  },[udpate])

  var count=-1;
  const renderToHtml=(data:any)=>{
    if(data.cloze){
      if(answer[data.code]===undefined) setAnswer({...answer,[data.code]:{
        position: null,
        questionCode: data.code,
        answerCode: "",
        extraParam: "false",
      }});
      count+=1;
      return <div className="flex">
        <div className="flex items-center px-2 pointer-events-none bg-gray-200 rounded-tl-md rounded-bl-md">{props.data['customPosition']+count}</div>
        <input 
          onClick={(e)=>{}}
          defaultValue={answer[data.code]?answer[data.code]['answerCode']:""}
          onInput={(e:any)=>{
            answer[data.code]['answerCode'] = e.target.value.trim(); 
            setAnswer(answer);
            setUdpate(Date.now());
          }}        
          type="text" 
          id="phone-input" 
          aria-describedby="helper-text-explanation" 
          className="border border-gray-300 text-gray-900 text-sm py-1 w-44 focus:ring-blue-500 focus:border-blue-500" 
          placeholder={t("enter_here")} 
          required />
        <div onClick={(e:any)=>{
          answer[data.code]['extraParam'] = `${answer[data.code]['extraParam']!="true"}`;
          setAnswer(answer);
          setUdpate(Date.now()); 
        }} className={`flex items-center rounded-tr-md rounded-br-md markBtn justify-center text-xs bg-blue-800 text-white w-12 font-normal text-center ${answer[data.code] && answer[data.code]['extraParam']!=="true"? "bg-orange-400":"bg-blue-800"}`}>
          {answer[data.code] && answer[data.code]['extraParam']=="true" ? t("unmark") : t("mark")}
        </div>
    </div>;
      // <span>
      //   <span className="border rounded-md p-1.5">{props.data['customPosition']+count}</span>
      //   <input 
      //   onClick={(e)=>{}}
      //   className="border-b-2 p-3 rounded-sm border-blue-400"
      //   defaultValue={answer[data.code]?answer[data.code]['answerCode']:""}
      //   placeholder="Enter here..."
      //   onInput={(e:any)=>{
      //     answer[data.code]['answerCode'] = e.target.value.trim(); 
      //     setAnswer(answer);
      //     setUdpate(Date.now());
      //   }}></input>
      //   <span onClick={(e:any)=>{
      //     answer[data.code]['extraParam'] = `${answer[data.code]['extraParam']!="true"}`;
      //     setAnswer(answer);
      //     setUdpate(Date.now()); 
      //   }} className={`markBtn px-2 py-1 text-sm bg-blue-800 text-white w-14 text-center rounded-md ${answer[data.code] && answer[data.code]['extraParam']!="true"? "bg-blue-800":"bg-orange-400"}`}>
      //     {answer[data.code] && answer[data.code]['extraParam']=="true" ? t("unmark") : t("mark")}
      //   </span>
      // </span>;
    }
    return <span dangerouslySetInnerHTML={{__html:data.clozeValue}}></span>;
  }

  return (
    <div className="">
      {data["showPosition"] && data.questionNumber && <span className="inline mt-1">{data.questionNumber}.&nbsp;</span>}
      {/* {data.renderedQuestion} */}
      <span className="inline mb-2" dangerouslySetInnerHTML={{ __html: data.question }}></span>

      <div className="relative overflow-x-auto p-2 bg-gray-200 rounded-md mt-5">
        <table className="w-full text-sm text-left rtl:text-right rounded-md text-gray-500 dark:text-gray-400">
            <tbody>
              {
                props.data.clozeTable.map((rows:any)=>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 ">
                  {
                    rows.map((cols:any)=>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300">
                      {renderToHtml(cols)}
                    </th>)
                  }
                </tr>)
              }
            </tbody>
        </table>
      </div>

    </div>
  );
};
export default QuestionClozeTable;
