const logoutButton=(ctx:any)=>{
    return <button onClick={()=>{
        goToCallback();
    }} className="focus:outline-none text-white bg-[#007BFF] hover:bg-blue-500 font-medium rounded-md text-sm px-10 py-2">{ctx.translate("log_out")}</button>
}
const goToCallback = () => {
    const url = sessionStorage.getItem("callback");
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace(url??"https://www.globalpsytech.com");
}
const deniedPermission = (t:any) => { 
    return <div className="">
        <p className="text-red-500">{t("permission_disable")}</p>
    </div>;
}
export { deniedPermission, goToCallback, logoutButton };
