import React, {
  useEffect,
  useState,
} from 'react';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { AnswerModel } from '../../model/answer_model';
import {
  Item3,
  MarksMap2,
} from '../../model/test_model';

const QuestionSliderImage = (props: any) => {
  var data: Item3 = props.data;
  const [selectedSlider, setSelectedSlider] = useState("");
  const [positionLeft, setPositionLeft] = useState(0); 
  const [positionRight, setPositionRight] = useState(0); 

  const setposition = (side: string, value: number) => {
    if (side === "left") {
      setSelectedSlider("left");
      setPositionLeft(value);
      setPositionRight(0); 
    } else if (side === "right") {
      setSelectedSlider("right");
      setPositionRight(value);
      setPositionLeft(0); 
    }

    if(data.scaleMin && value<=data.scaleMin){
      props.onAnswer(new AnswerModel());
      return;
    }

    if (side !== "" && value > 0) {
      const answerModel = new AnswerModel({
        questionCode: data.questionCode,
        isMarked: false,
        answerData: {
          alphaData: side,
          numericData: value,
        },
      });
      props.onAnswer(answerModel);
    }else{
      props.onAnswer(new AnswerModel());
    }
  };

  useEffect(() => {
    debugger;
    // Set the selected slider and its position from the data
    if (data["answer"] && data["answer"]["answerData"] && data["answer"]["answerData"]["numericData"]) {
      const alphaData = data["answer"]["answerData"]["alphaData"];
      const numericData = data["answer"]["answerData"]["numericData"];
      setSelectedSlider(alphaData);
      if (alphaData === "left") {
        setPositionLeft(numericData);
      } else if (alphaData === "right") {
        setPositionRight(numericData);
      }
    }
  }, []);

  const marks = [
    {
      value: 0,
      label: "Strongly Disagree",
    },
    {
      value: 100,
      label: "Strongly Agree",
    },
  ];

  function valuetext(value: number) {
    return `${value}`;
  }

  const imgUrlLeft = `https://nxbas-prod.sgp1.cdn.digitaloceanspaces.com/question-assets/${data.leftPairImage}.png`;
  const imgUrlRight = `https://nxbas-prod.sgp1.cdn.digitaloceanspaces.com/question-assets/${data.rightPairImage}.png`;

  return (
    <div className="mb-10">
      {data["showPosition"] && data.questionNumber && <span className="inline mt-1">{data.questionNumber}.&nbsp;</span>}
      {data.renderedQuestion}
      {/* <span
        className="inline pb-5"
        dangerouslySetInnerHTML={{ __html: data.question }}
      ></span> */}
      <div className="grid grid-cols-2 md:gap-36 mt-10 md:max-w-7xl">
        <div key="QUS-1">
          <div onChange={() => setposition("left", 0)} className="flex">
            <input
              name="chk"
              checked={selectedSlider === "left"}
              type="radio"
              className="mr-2 inline"
              style={{ marginTop: 2 }}
            ></input>
            {data.leftPairImage !== null && data.leftPairImage !== "" ? (
              <img
                className="min-w-[80px] w-[50%] mb-6 rounded-md inline"
                src={imgUrlLeft}
                alt="no img..."
              ></img>
            ) : (
              <></>
            )}
            <div
              className={`flex items-start ${
                selectedSlider === "" && data.highlight === "tutorial"
                  ? "shadow_animation rounded-md p-2"
                  : ""
              }`}
            >
            </div>
          </div>
          <div className="ml-2 absolute">
            <Box className="min-w-[100px] w-[37vw] md:w-[25vw] max-w-[450px]">
              <Slider
                aria-label="Restricted values"
                value={selectedSlider==="left"? positionLeft:0}
                defaultValue={0}
                getAriaValueText={valuetext}
                step={marks.length>2?null:data.scaleMax} 
                valueLabelDisplay="auto"
                sx={{
                  width: "100%",
                  height: "30%",
                  // backgroundImage: selectedSlider === "left" ? `linear-gradient(90deg, skyblue ${(positionLeft / data.scaleMax) * 100}%, transparent 0%)` : "",
                  borderRadius: "8px", 
                  color: "skyblue",
                }}
                onChange={(event, value) => {
                  const sliderValue = Array.isArray(value) ? value[0] : value;
                  setposition("left", sliderValue);
                }}
                max={data.scaleMax}
                min={data.scaleMin}
                className={`w-full h-2 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 
                  ${selectedSlider !== "" && data.highlight === "tutorial" ? "shadow_animation" : ""}`
                }
              />
            </Box>
            <div className="flex items-center justify-between min-w-[120px] w-[37vw] md:w-[30vw] max-w-[450px]">
              {marks.map((ele,index) => (<p className="w-20 font-normal text-xs">{ele.label}</p>))}
            </div> 
          </div>
        </div> 
        <div key="QUS-2">
          <div onChange={() => setposition("right", 0)} className="flex">
            <div
              className={`flex items-start ${
                selectedSlider === "" && data.highlight === "tutorial"
                  ? "shadow_animation rounded-md p-2"
                  : ""
              }`}>
              <input
                name="chk"
                checked={selectedSlider === "right"}
                type="radio"
                className="mr-2"
                style={{ marginTop: 2 }}
              ></input>
            </div>

            {data.rightPairImage !== null && data.rightPairImage !== "" ? (
              <img
                className="min-w-[80px] w-[50%] mb-6 rounded-md inline"
                src={imgUrlRight}
                alt="no img..."
              ></img>
            ) : (
              <></>
            )}
          </div>
          <div className="ml-3">
            <Box className="min-w-[100px] w-[37vw] md:w-[25vw] max-w-[450px]">
              <Slider
                aria-label="Restricted values"
                value={selectedSlider==="right"? positionRight:0}
                defaultValue={0}
                getAriaValueText={valuetext}
                step={marks.length>2?null:data.scaleMax} 
                valueLabelDisplay="auto"
                sx={{
                  width: "100%",
                  height: "30%",
                  backgroundImage: selectedSlider === "right" ? `linear-gradient(90deg, skyblue ${(positionRight / data.scaleMax) * 100}%, #cee0ff 0%)` : "",
                  color: "skyblue"
                }}
                onChange={(event, value) => {
                  const sliderValue = Array.isArray(value) ? value[0] : value;
                  setposition("right", sliderValue);
                }}
                max={data.scaleMax}
                min={data.scaleMin}
                className={`w-full h-2 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 ${
                  selectedSlider !== "" && data.highlight === "tutorial" ? "shadow_animation" : ""
                }`}
              />
            </Box>
            <div className="flex items-center justify-between min-w-[120px] w-[37vw] md:w-[23vw] max-w-[450px]">
              {marks.map((ele,index) => (
                <p className="w-20 font-normal text-xs">{ele.label}</p>
              ))}
            </div> 
          </div>
          <div className="max-w-full text-xs font-semibold flex flex-wrap items-center justify-between mx-2 py-2">
            {data.marksMap?.map((ele: MarksMap2, index: number) => (
              <span
                key={`OPTR-${index}`}
                dangerouslySetInnerHTML={{ __html: ele.label }}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default QuestionSliderImage;
