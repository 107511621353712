import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { BsInfoCircle } from 'react-icons/bs';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import { logoutButton } from '../../../components/common/page_components';
import {
  routingQuestionDecision,
} from '../../../components/common/question_position_decision';
import { UserContext } from '../../../context_provider/user_provider';
import endpoints from '../../../data/network/API_ENDPOINT';
import HTTP from '../../../data/network/HTTP';
import i18n from '../../../i18n/i18n';
import routingPath from '../../../routing/router_path';

function StartTestSetup() {
  const location = useLocation();
  const navigate = useNavigate();
  const ctx:any = useContext(UserContext);
  const [failed, setFailed] = useState(false);

  // update public.test_taker set status='NOT_STARTED', dpa_accepted=false, demographic_section_completed=false, last_visited_question_position=0, last_visited_section=0

  const initData = async(urlParams:any)=>{
    let userResponse = await HTTP.GET(endpoints.userProfile);
    if(userResponse.status===200){
      sessionStorage.setItem("user",userResponse.data);
      ctx.setUser(userResponse.data);
    }else{
      toast.error(ctx.t("failed_to_start_test"));
      setFailed(true);
      return;
    }
    let testResponse = await HTTP.GET(`${endpoints.startResume}/${urlParams['tt']}`);
    if (testResponse.status === 200) {
      ctx.setTest(testResponse.data.test);
      ctx.setTestTaker({...testResponse.data,"test":undefined});
    } else {
      toast.error(ctx.t("start_test_fail"));
      return;
    }
    i18n.changeLanguage(testResponse.data.language.toLowerCase());
    ctx.setLng(testResponse.data.language.toLowerCase());
    
    if(!urlParams['no_proctoring'] && testResponse.data && testResponse.data.proctorUrl && testResponse.data.proctorToken){
      const form = document.createElement('form')
      form.method = 'POST'
      form.action = testResponse.data.proctorUrl;
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = 'token'
      hiddenField.value = testResponse.data.proctorToken;
      form.appendChild(hiddenField)
      document.body.appendChild(form)
      form.submit()
      return;
    }

    document.documentElement.dir = testResponse.data.language?.toLowerCase()==="ar"? "rtl":"ltr";
    let routsAll = routingQuestionDecision(testResponse.data.test,testResponse.data);
    console.log("Routs >> ",routsAll);
    let nextRouts = routsAll.shift()
    ctx.setRouts(routsAll);
    switch (testResponse.data["status"]) {
      case "NOT_STARTED":
        i18n.changeLanguage(testResponse.data.language.toLowerCase());
        ctx.setLng(testResponse.data.language.toLowerCase());
        navigate(`${nextRouts}`,{replace:true});
        break;
      case "STARTED":
        i18n.changeLanguage(testResponse.data.language.toLowerCase());
        ctx.setLng(testResponse.data.language.toLowerCase());
        navigate(`${nextRouts}`,{replace:true});
        break;
      case "ANSWERING":
        navigate(`${nextRouts}`,{replace:true});
        break;
      case "FINISHED":
        navigate(routingPath.testTakingFinish,{replace:true});
        break;
      case "TIME_OUT":
        navigate(routingPath.TestTakingTimeOut,{replace:true});
        break;
      default:
        toast.error((("Invalid action : ")) + testResponse.data["status"]);
        setFailed(false);
        break;
    }
  }

  useEffect(()=>{
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    
    let data:any = {};
    let urlData = location.search;
    sessionStorage.setItem("initurl",location.search);
    urlData.replace("?","").split("&").forEach((pair)=>{
      if(pair.split("=").length===2){
        data[pair.split("=")[0]]=pair.split("=")[1];
      }
    });
    sessionStorage.setItem("token",data["token"]);
    if(data["callback"])
      sessionStorage.setItem("callback",data["callback"]);
    initData(data);
  },[])

  return (
    <div className="flex justify-center self-center absolute top-0 bottom-0 right-0 left-0">
      {
        failed?
        <div className="text-red-500">
          <p className="ml-[38%]  text-4xl mb-5"><BsInfoCircle/></p>
          <p>Sorry! Failed to load test</p>
          <div style={{textAlignLast:'center'}} className="mt-5">{logoutButton(ctx)}</div>
        </div>
        :<p>{ctx.t("loading_test")}...</p>
      }
    </div>
  );
}

export default StartTestSetup;
