enum endpoints {
    userProfile = "user/profile",
    startResume = "test-taking/resume-test",
    acceptDPA = "test-taking/accept-dpa",
    submitDemographic = "test-taking/submit-demographic",
    answerDemographic = "test-taking/answer-demographic",
    saveDemographic = "test-taking/save-demographic",
    partialSaveAnswer = "test-taking/save-partial-answer",
    submitPicture = "test-taking/capture-picture" ,
    submitAudio = "test-taking/upload-audio",
    submitAnswer = "test-taking/submit-answer",
    submitAnswerList = "test-taking/save-question-list",
    getAnswerList = "test-taking/get-question-list",
    nextQuestion = "test-taking/next-question",
    startQuestion = "test-taking/start-question",
    previousQuestion = "test-taking/prev-question",
    timeOut = "test-taking/time-out",
}
export default endpoints;