import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  bignumber,
  evaluate,
  format,
} from 'mathjs';
import { BsFillCalculatorFill } from 'react-icons/bs';

interface SideBarGlossaryProps {
    value: boolean;
    setValue: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideBarCalculator: React.FC<SideBarGlossaryProps> = ({ value: calc, setValue: setCalc }) => {
    const [result, setResult] = useState("");
    const [isHorizontal, setIsHorizontal] = useState(false);
    const [resultDisplayed, setResultDisplayed] = useState(false);
    const resultRef = useRef<HTMLInputElement>(null); // Define resultRef as a useRef

    const appendCharacter = (char: string) => {
        const operators = ['+', '-', '*', '/'];
        setResultDisplayed(prevState => {
            if (prevState) {
                // clearResult();
                if(!operators.includes(char)){
                    setResult(char)
                }
                return false;
            }
            return prevState;
        });

        // If setResult(char) was executed, we return early
        if (resultDisplayed && !operators.includes(char)) {
            return;
        }

        const lastChar = result[result.length - 1];
        if (operators.includes(lastChar) && operators.includes(char)) {
            return; // Do not append the new character
        }
        setResult(prevResult => prevResult + char);
    };

    const clearResult = () => {
        setResult("");
    };

    const delResult = () => {
        setResult(prevResult => prevResult.slice(0, -1));
    };

    const calculateResult = () => {
        try {
            const evaluatedResult = evaluate(result);
            const formattedResult = format(evaluatedResult, { precision: 14 }).toString();
            const bigNumberResult = bignumber(formattedResult).toString();
            // setResult(formattedResult);
            setResult(bigNumberResult);
            setResultDisplayed(true);
        } catch (e) {
            setResultDisplayed(true);
            setResult("Error");
        }
    };

    useEffect(() => {
        if (resultDisplayed && resultRef.current) {
            resultRef.current.scrollLeft = resultRef.current.scrollWidth;
            console.log("Result Displayed after calculation");
        }
    }, [resultDisplayed]);

    useEffect(()=>{
        let foo = document.getElementById("result");
        if(foo)
            foo.scrollLeft = foo.scrollWidth;
    },[result])

    return (
        <div>
            <button
                className="absolute right-0 bottom-14 bg-blue-500 p-2 rounded-tl-lg rounded-bl-lg z-20"
                onClick={() => {
                    setCalc(!calc);
                    setIsHorizontal(!isHorizontal);
                }}
                style={{ borderTopLeftRadius: 10 }}
            >
                <BsFillCalculatorFill size={30} color='white' />
            </button>

            {calc && (
                <div id="calculator-view" className="shadow-md bg-gray-200 w-82 h-74 mr-12">
                    <div className="bg-gray-100">
                        <div className="bg-white p-8 shadow-lg border-2 border-green-500 rounded-lg">
                            <input
                                type="text"
                                id="result"
                                value={result}
                                ref={resultRef}
                                className="w-full bg-gray-200 text-right p-4 mb-4 border border-gray-300 rounded-md focus:outline-none"
                                placeholder="0"
                                readOnly
                            />
                            <div className="grid grid-cols-4 gap-2">
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={clearResult}>C</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={delResult}>Del</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('(')}>(</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter(')')}>)</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('7')}>7</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('8')}>8</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('9')}>9</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('/')}>/</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('4')}>4</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('5')}>5</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('6')}>6</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('*')}>*</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('1')}>1</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('2')}>2</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('3')}>3</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('-')}>-</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('0')}>0</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('.')}>.</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={calculateResult}>=</button>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => appendCharacter('+')}>+</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export { SideBarCalculator };
