import React, {useEffect, useState} from 'react';

import { SideBarCalculator } from './side_drawer_calculator';
import { SideBarGlossary } from './side_drawer_glossary';
import {initFlowbite} from "flowbite";

const SideBar = (props: any) => {
    const [menu] = useState(true);
    const [calc, setCalc] = useState(false);
    const [glossary, setGlossary] = useState(false);
    useEffect(()=>{
        initFlowbite();
    },[glossary,calc])

    return (
        <div
            className={`fixed bottom-14 right-0 z-50 transition delay-150 duration-300 ease-in-out ${menu? 'w-40':'w-12'}]}`}>
            <div>
                <SideBarCalculator value={calc} setValue={(value)=>{setGlossary(false);setCalc(value);}}/>
                <SideBarGlossary value={glossary} setValue={(value)=>{setGlossary(value);setCalc(false);}}/>
            </div>
        </div>
    );
}

export { SideBar };
