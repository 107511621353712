import 'react-confirm-alert/src/react-confirm-alert.css';

import {
  useContext,
  useEffect,
  useState,
} from 'react';

import { initFlowbite } from 'flowbite';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Qtimer from '../../../components/common/question_timer';
import QuestionHandler
  from '../../../components/question_handler/question_handler';
import { SideBar } from '../../../components/sidebar-option/side_drawer';
import { UserContext } from '../../../context_provider/user_provider';
import endpoints from '../../../data/network/API_ENDPOINT';
import HTTP from '../../../data/network/HTTP';
import { AnswerModel } from '../../../model/answer_model';
import ApiResponseModel from '../../../model/api_response';
import { ErrorModel } from '../../../model/error_model';
import {
  QuestionItem,
  TestSection,
} from '../../../model/test_model';
import routingPath from '../../../routing/router_path';

function TestTakingQuestion() {
  const [lastSection, setLastSection] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ctx: any = useContext(UserContext);
  const [answer, setAnswer] = useState<AnswerModel>(new AnswerModel()); 
  const [question, setQuestion] = useState<TestSection>();
  const [, setQuestionItem] = useState<QuestionItem>();
  const [isMark, setIsMark] = useState(false);
  const [lastTimeAnswered, setLastTimeAnswered] = useState(0);
  const [timer, settimer] = useState(0);
  const [showTimer, setShowTimer] = useState(false);
  const [partialTimer, setPartialTimer] = useState(0);
  const [markedOrAnswered, setMarkedOrAnswered] = useState<any[]>()
  const [currentIndex, setcurrentIndex] = useState(1);

  const currentQuestionPositionRender=()=>{
    if(question==null) {return "Loading...";}
    let atQuestion:any = question.item.position;
    if(question.item.type==="PAS" || question.item.type==="CZ" || question.item.type==="CZT"){
      let questionList:any = [];
      markedOrAnswered?.forEach((item,index) => {
        if(item['questionCode']===question.item.questionCode){
          questionList.push(index+1);
        }
      });
      atQuestion = `${questionList.shift()}-${questionList.pop()}`;
    }
    return `${atQuestion} ${t("out_of")} ${markedOrAnswered?.length}`;
  }

  const currentQuestionPositionStart=()=>{
    if(question==null) {return "Loading...";}
    let atQuestion:any = question.item.position;
    if(question.item.type==="PAS" || question.item.type==="CZ" || question.item.type==="CZT"){
      let questionList:any = [];
      markedOrAnswered?.forEach((item,index) => {
        if(item['questionCode']===question.item.questionCode){
          questionList.push(index+1);
        }
      });
      atQuestion = questionList.shift();
    }
    return atQuestion;
  }

  const timeLogger = async () => {
    debugger;
    if(question?.item.questionCode && question?.item.sectionNumber){
      var timeTaken = 0;
      try {
        console.log(">>",timer,lastTimeAnswered);
        timeTaken =  timer-lastTimeAnswered;
        setLastTimeAnswered(timer);
      } catch (error) {}
      // console.log(timer,lastTimeAnswered);
      await HTTP.POST(endpoints.partialSaveAnswer, {
        ...answer,
        testTakerId: `${ctx.testTaker["id"]}`,
        questionPosition: question?.item.questionNumber,
        sectionNumber: ((question?.item.sectionNumber)??1)-1,
        questionCode: question?.item.questionCode,
        questionType: question?.item.type,
        spendTime: timeTaken,
        maxRetry: answer.maxRetry??0,        
        isMarked: isMark??false,
        testId: ctx.testTaker['testId']
      });  
    } 
  }

  useEffect(()=>{
    // console.log(timer,lastTimeAnswered);
    if(timer%14==0) timeLogger();
  },[timer])

  useEffect(() => {
    initFlowbite();
    debugger;
    if (question && lastSection !== question.sectionNumber) {
      if(question.showStartOfSectionMessage===true){
        setShowTimer(false);
        document.getElementById("pop")?.click();          
        setLastSection(question.sectionNumber);
      }else{
        setLastSection(question.sectionNumber);
        setShowTimer(true)
      }
      settimer(0);
      setLastTimeAnswered(0);
    }
    if (question && question?.item && question?.item.answer) {
      setIsMark(question?.item.answer.isMarked);
    } else {
      setIsMark(false);
    }
    if (question === undefined) {
      loadNewQuestion(endpoints.startQuestion,undefined);
    }
  }, [question]);

  const timeOut = async () => {
    ctx.setBusy(true);
    try {
      var response: ApiResponseModel = await HTTP.GET(`${endpoints.timeOut}/${ctx.testTaker["id"]}`);
      if (response.data === "Time out") {
        toast.warning(t("section_timeOut")); 
        navigate(routingPath.TestTakingTimeOut);
        ctx.setBusy(false);
        return;
      }
      if (response.data === "Test completed") {
        toast.warning(t("section_timeOut"));
        navigate(routingPath.testTakingFinish);
        console.error("data is empty");
        return;
      }
      if (response.status === 200) {
        setAnswer(new AnswerModel());
        confirmAlert({
          title: t("time_out"),
          message: t("section_timeOut"),
          onClickOutside: () => {
            console.log(response);
            setAnswer(new AnswerModel());
            if (response.data) {
              if (response.data === "Test completed") {
                ctx.setBusy(false);
                navigate(routingPath.testTakingFinish);
                console.error("data is empty");
                return;
              }
              response.data.item.answer = response.data.response;
              setcurrentIndex(response.data.item.position);
              if (response.data.item.answer) {
                setAnswer(response.data.item.answer);
              }
              if (response.data.markedAndAnswered)
                setMarkedOrAnswered(response.data.markedAndAnswered['grid']);
              if(response.data.markedAndAnswered && response.data.markedAndAnswered['spendTime'] && response.data.markedAndAnswered['spendTime']>0){
                response.data.timeLimit = response.data?.timeLimit-response.data.markedAndAnswered['spendTime'];
              } else{
                response.data.timeLimit = response.data?.timeLimit-0;
              }
              setQuestionItem(response.data.item);
              setQuestion(response.data);
            }
          },
          closeOnClickOutside: true,
          buttons: [
            {
              label: (t("next_section")),
              onClick: () => {
                console.log(response);
                setAnswer(new AnswerModel());
                if (response.data) {
                  if (response.data === "Test completed") {
                    ctx.setBusy(false);
                    navigate(routingPath.testTakingFinish);
                    console.error("data is empty");
                    return;
                  }
                  response.data.item.answer = response.data.response;
                  setcurrentIndex(response.data.item.position);
                  if (response.data.item.answer) {
                    setAnswer(response.data.item.answer);
                  }
                  if (response.data.markedAndAnswered)
                    setMarkedOrAnswered(response.data.markedAndAnswered['grid']);
                  if(response.data.markedAndAnswered && response.data.markedAndAnswered['spendTime'] && response.data.markedAndAnswered['spendTime']>0){
                    response.data.timeLimit = response.data?.timeLimit-response.data.markedAndAnswered['spendTime'];
                  } else{
                    response.data.timeLimit = response.data?.timeLimit-0;
                  }
                  setQuestionItem(response.data.item);
                  setQuestion(response.data);
                }
              },
            },
          ],
        });
      } else {
      }
    } catch (error) {
      console.error(error);
    }
    ctx.setBusy(false);
  };

  const loadNewQuestion = async (url:string,position: any) => {
    debugger;
    ctx.setBusy(true);
    var timeTaken = 0;
    try {
      console.log(">>",timer,lastTimeAnswered);
      timeTaken =timer-lastTimeAnswered;
      setLastTimeAnswered(timer);
    } catch (error) {}
    try {
      let response = await HTTP.POST(url, {
        testTakerId: `${ctx.testTaker["id"]}`,
        questionPosition: question?.item.questionNumber,
        sectionNumber: question?.item.sectionNumber,
        questionCode: question?.item.questionCode,
        questionType: question?.item.type,
        isMarked: isMark??false,
        spendTime: timeTaken,
        answerId: answer.answerId,
        answerData: answer.answerData,
        isAnswered: answer.isAnswered??true,
        targetQuestionPosition:position,
        maxRetry: answer.maxRetry??0,
      });
      debugger;
      if (response.status === 200) {
        setAnswer(new AnswerModel());
        if (response.data) {
          if (response.data === "Test completed") {
            ctx.setBusy(false);
            navigate(routingPath.testTakingFinish);
            console.error("data is empty");
            return;
          }
          response.data.item.answer = response.data.response;
          setcurrentIndex(response.data.item.position);
          if (response.data.item.answer) {
            setAnswer(response.data.item.answer);
          }
          if (response.data.markedAndAnswered)
            setMarkedOrAnswered(response.data.markedAndAnswered['grid']);
          if(response.data.markedAndAnswered && response.data.markedAndAnswered['spendTime'] && response.data.markedAndAnswered['spendTime']>0){
            response.data.timeLimit = response.data?.timeLimit-response.data.markedAndAnswered['spendTime'];
          } else{
            response.data.timeLimit = response.data?.timeLimit-0;
          }
          setQuestionItem(response.data.item);
          setQuestion(response.data);
        }
      } else { 
        if(ctx.token!=="")
          toast.error(t("tt_retrive_fail"));
      }
    } catch (error) {
      console.error(error);
    }
    ctx.setBusy(false);
  };
  
  return question === undefined ? (
    <div className="absolute top-[50vh] left-[50vw]">Loading...</div>
  ) : (
    <div className="">
      <SideBar></SideBar>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-1 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <img src="/images/nxbas.avif" className="h-12" alt="NxBAS Logo" />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 items-center text-center">
              <p className="text-sm sm:mr-6 uppercase">
                ID : {ctx.user["username"]}
              </p>
              {
                question && showTimer && <Qtimer
                  key={`QT${question?.sectionNumber}`}
                  onFinish={() => {
                    timeOut();
                  }}
                  onLow={() => {
                    console.error("low");
                  }}
                  onEach={(tick) => {
                    settimer(timer+1);
                  }}
                  seconds={question.timeLimit}
                ></Qtimer>
              } 
            </div>
          </div>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700 webkit-auto">
          <div className="bg-blue-500 h-1 rounded-full" style={{width: (markedOrAnswered!=null?(((currentIndex)*100) / markedOrAnswered!.length):"0")+"%"}}></div>
        </div>
      </nav>
      <div className="md:p-4 my-20">
        <div
          onClick={()=>{setShowTimer(true)}}
          id="default-modal"
          tabIndex={-1}
          aria-hidden="false"
          className="hidden shadow-md bg-white w-full h-full fixed top-0 right-0 left-0 z-50">
          <div className="">
            <div className="w-full text-center mt-30">
              <div className="text-md text-left font-normal text-black md:mx-40 lg:mx-60 xl:mx-96 xl:px-20">
                <p dangerouslySetInnerHTML={{__html: ctx.test["translations"]? ctx.test["translations"]["testSectionTranslations"][ctx.Qsection]["startOfSectionInstruction"]:""}}></p>
              </div>
              <p dangerouslySetInnerHTML={{__html: question.sectionStartMessage}}></p>
              <div className="text-start text-md text-gray-800 max-w-md lg:max-w-xl flex flex-wrap items-center justify-between mx-5 md:mx-auto">
                <span dangerouslySetInnerHTML={{__html:question.sectionInstruction}}></span>
              </div>
              <br/>
              <br/>
              <button
                // onClick={()=>{setShowTimer(true)}}
                data-modal-hide="default-modal"
                type="button"
                className="m-5 bg-blue-500 text-white p-2 px-8 rounded-md"
              >
                {t("tt_ins_start")}
              </button>
            </div>
          </div>
        </div>
        <button
          id="pop"
          data-modal-target="default-modal"
          data-modal-toggle="default-modal"
          className="block"
          type="button"
        ></button>
        <br/>
        <div className="w-[100%] lg:flex lg:justify-between">
          <div 
            key={`QKEY${question?.item.position}`}
            className="sm:m-10 md:m-4 block overflow-y-auto w-full p-4 md:p-0 max-w-[100%]">
              {question?.totalSections > 1 && (
                <p className="text-left font-bold">
                  {t("section")} {question?.item.sectionNumber}
                </p>
              )}
              <p className="">
                {currentQuestionPositionRender()}
              </p>
              <br/>
              <div key={`QH${question?.sectionNumber}`}>
                <QuestionHandler
                  onError={(error:ErrorModel)=>{
                    console.log(error);
                  }}
                  onAnswer={(newAnswer: any) => {
                    console.log({...newAnswer});
                    if(question.item.type=="CZ" || question.item.type=="CZT" || question.item.type=="PAS"){
                      setIsMark(newAnswer && newAnswer.isMarked==true);
                    } 
                    setAnswer({...answer,...newAnswer});
                  }}
                  data={{
                    ...question?.item,
                    'answer':(question?.item.answer && (question?.item.answer['maxRetry'] || question?.item.answer['answerId'] || question?.item.answer['answerData']))?question?.item.answer:null,
                    itemIndex:currentIndex,
                    customPosition:currentQuestionPositionStart()
                  }}
                  key={`qus${question?.item.questionNumber}`}
                ></QuestionHandler>
              </div>
              <br/>
              <br/>
              <div className="w-full text-center mb-10">
                {ctx.test.prevButtonEnabled ? (
                  <button
                    disabled={question.item.position <= 1}
                    className="mx-1 sm:mx-4 disabled:bg-gray-300 bg-gray-600 text-white p-2 px-4 rounded-md"
                    onClick={() => {
                      loadNewQuestion(endpoints.previousQuestion,question?.item.questionNumber-2);
                      setcurrentIndex(currentIndex-1);
                    }}>
                    {t("prev")}
                  </button>
                ) : (
                  <></>
                )}

                {ctx.test.markItemEnabled && question?.item.type!=="PAS" && question?.item.type!=="CZ" && question?.item.type!=="CZT" ? (
                <button
                  className={`${!isMark? "bg-orange-400":"bg-blue-800"} mx-1 sm:mx-4 text-white p-2 px-6 rounded-md`}
                  onClick={() => {
                    // answer.isMarked=!isMark
                    // setAnswer(answer);
                    markedOrAnswered?.forEach((item,index)=>{
                      markedOrAnswered[question.item.position-1]['isMarked']=!isMark;
                      setMarkedOrAnswered(markedOrAnswered);
                    });
                    setIsMark(!isMark);
                  }}>{isMark ? t("unmark") : t("mark")}</button>
                ) : (<></>)}

                {markedOrAnswered &&  markedOrAnswered[markedOrAnswered?.length-1]['questionCode'] === question.item.questionCode ? (
                  <button
                    disabled={!(answer && answer.isAnswered && (answer.answerId || answer.answerData)) || isMark}
                    className="mx-1 sm:mx-4 disabled:bg-gray-300 bg-blue-500 text-white p-2 px-6 rounded-md"
                    onClick={() => {
                      if (ctx.test.markItemEnabled) {
                        console.log("Checking for marked and answered question...");
                        let isAnswered = markedOrAnswered?.filter((item)=> item['questionCode']!==question.item.questionCode && !item['isAnswered']);
                        if (isAnswered && isAnswered.length>0) {
                          confirmAlert({
                            childrenElement: ()=>{return <span>
                              <p className="font-bold mb-3">{t("unans_ques")}</p>
                              <p className="mb-5">{t("unans_ques_desc")}</p>
                            </span>},
                            closeOnClickOutside: true,
                            buttons: [
                              {
                                label: "Ok", 
                                onClick: () => {},
                              },
                            ],
                          });
                          return;
                        }
                        let isMarked = markedOrAnswered?.filter((item)=> item['questionCode']!==question.item.questionCode && item['isMarked']);
                        if (isMark || (isMarked && isMarked.length>0)) {
                          confirmAlert({
                            childrenElement: ()=>{return <span>
                              <p className="font-bold mb-3">{t("marked_ques")}</p>
                              <p className="mb-5">{t("marked_ques_desc")}</p>
                            </span>},
                            closeOnClickOutside: true,
                            buttons: [ 
                              {
                                label: "Ok",
                                onClick: () => {},
                              },
                            ],
                          });
                          return;
                        }
                      }
                      loadNewQuestion(endpoints.nextQuestion,question?.item.questionNumber);
                    }}>
                    {t("finish")}
                  </button>
                ) : (
                  <button
                    disabled={!((answer && answer.isAnswered && (answer.answerId || answer.answerData)) || isMark)}
                    className="mx-1 sm:mx-4 disabled:bg-gray-300 bg-blue-500 text-white p-2 px-6 rounded-md"
                    onClick={() => {
                      loadNewQuestion(endpoints.nextQuestion,question?.item.questionNumber);
                      setcurrentIndex(currentIndex+1);
                    }}>
                    {t("next")}
                  </button>
                )}
              </div>
          </div>

          {ctx.test.markItemEnabled ? (
            <div className="pt-32 lg:mr-10 lg:ml-20 text-xs lg:max-w-64" key={`QS${question?.sectionNumber}`}>
              <div
                key={`QS-${question?.sectionNumber}`}
                className="p-2 bg-white rounded-md mx-2 shadow-md mb-2 lg:flex lg:flex-wrap"
                style={{ display: "ruby-text", textAlign: "justify" }}>
                {markedOrAnswered?.map((e, i) => (
                    <p onClick={() => {
                      if(question?.item.questionCode !== e.questionCode){
                        loadNewQuestion(endpoints.startQuestion,e.parentIndex);
                        setcurrentIndex(e.parentIndex+1);
                      }
                    }}
                    className={(
                      question?.item.questionCode === e.questionCode? "bg-blue-400 text-white"
                      : e.isMarked? "bg-orange-400 font-medium text-white "
                      : e.isAnswered? " bg-green-600 font-medium text-white "
                      : " bg-gray-500 font-medium text-white ")
                      + " border cursor-pointer m-0.5 rounded-md w-6 py-0.5 align-middle text-center text-xs"
                    }>
                      {/* {e['questionPosition']} */}
                      {i+1}
                    </p>
                ))}
              </div>
              <div className="p-2 bg-white rounded-md mx-2 shadow-md">
                <p className="text-left flex items-center">
                  <div className="mr-1 h-5 w-5 rounded-md bg-green-600"></div>
                  {t("answered")}
                </p>
                <p className="text-left flex items-center my-1">
                  <div className="mr-1 h-5 w-5 rounded-md bg-orange-400"></div>
                  {t("marked")}
                </p>
                <p className="text-left flex items-center">
                  <div className="mr-1 h-5 w-5 rounded-md bg-gray-500"></div>
                  {t("unanswered")}
                </p>
                <p className="text-left flex items-center mt-1">
                  <div className="mr-1 h-5 w-5 rounded-md bg-blue-400"></div>
                  {t("answering")}
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );

}

export default TestTakingQuestion;
