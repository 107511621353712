import {
  useContext,
  useEffect,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  goToCallback,
  logoutButton,
} from '../../../components/common/page_components';
import { UserContext } from '../../../context_provider/user_provider';
import endpoints from '../../../data/network/API_ENDPOINT';
import HTTP from '../../../data/network/HTTP';

function DPA() {
  const navigate = useNavigate();
  const ctx:any = useContext(UserContext);
  const { t } = useTranslation();

  const agreeDPA = async (accepted:boolean) => {
    if(accepted){
      let response = await HTTP.GET(`${endpoints.acceptDPA}/${ctx.testTaker['id']}`);
      if(response.status===200){
        navigate(`${ctx.nextRoute()}`,{replace:true});
      } else
        toast.error(t("failed_accept_DPA"));
    }else{
      goToCallback();
    }
  }
  
  return (
    <div className="">
        <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
            <div className="max-w-full flex flex-wrap items-center justify-between mx-auto p-4">
                <img src="/images/nxbas.avif" className="h-14" alt="NxBAS Logo" />
                <div className="grid grid-cols-1 sm:grid-cols-2 items-center text-center">
                  <p className="text-sm sm:mr-6 uppercase">ID : {ctx.user['username']}</p>
                  {logoutButton(ctx)}
                </div>
            </div>
        </nav>
        <hr/>
        <div className="text-center max-w-4xl mt-3 md:mt-10 mx-2 md:mx-auto block p-2 md:p-8 bg-white border border-gray-200 rounded-lg shadow ">
          <p className="text-lg font-semibold text-center mb-5">{t("dpa_title")}</p> 
          <div className="h-2/4 max-h-80 md:max-h-96 border-gray-200 rounded-md overflow-y-auto shadow text-justify p-2">
            <span dangerouslySetInnerHTML={{__html:t("dpa")}}></span>
          </div>
          <div className="flex justify-around mx-auto mt-16 mb-6">
            <button onClick={()=>{agreeDPA(false)}} className="bg-white text-blue-500 font-medium rounded-md text-xs px-5 py-2 border border-blue-500">{t("dont_agree")}</button>
            <button onClick={()=>{agreeDPA(true)}} className="focus:outline-none text-white bg-blue-500 hover:bg-blue-800 font-medium rounded-md text-xs px-5 py-2">{t("agree")}</button>
          </div>
        </div>

    </div>
  );

}
export default DPA;
