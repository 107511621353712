import 'react-confirm-alert/src/react-confirm-alert.css';

import {
  useContext,
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Qtimer from '../../../components/common/question_timer';
import QuestionHandler
  from '../../../components/question_handler/question_handler';
import { SideBar } from '../../../components/sidebar-option/side_drawer';
import { UserContext } from '../../../context_provider/user_provider';
import endpoints from '../../../data/network/API_ENDPOINT';
import HTTP from '../../../data/network/HTTP';
import { AnswerModel } from '../../../model/answer_model';
import { ErrorModel } from '../../../model/error_model';
import routingPath from '../../../routing/router_path';

function TestTakingQuestionLIstVIew() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ctx: any = useContext(UserContext);
  const [answers, setAnswers] = useState<any>({});
  const [currentSection, setCurrentSection] = useState(ctx.testTaker?ctx.testTaker.lastVisitedSection:0);
  const [totalTime, setTotalTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [force, setForce] = useState(0);

  const saveAnswers=(questionAnswers:any,direction:string="save")=>{
    if(direction!=="save") ctx.setBusy(true);
    let answerList:any = questionAnswers.filter((answer:any) => answer && answer.questionCode && (answer.answerData || answer.answerId));
    let payload = {
      answers: answerList,
      testId: ctx.testTaker['testId'],
      testTakerId: `${ctx.testTaker["id"]}`,
      scheduleId: ctx.testTaker["scheduleId"],
      spendTime: currentTime,
      direction: direction,
    };
    HTTP.POST(endpoints.submitAnswerList,payload).then((response)=>{
      if(direction==="finish") {
        navigate(routingPath.testTakingFinish);
      } else if(direction==="next") {
        setAnswers({});
        setCurrentSection(currentSection+1);
      } else if(direction==="timeout") {
        if(ctx.test.testSections.length===(currentSection+1)){
          navigate(routingPath.TestTakingTimeOut);
        }else{
          setCurrentSection(currentSection+1);
        }
      }
      ctx.setBusy(false);
    }).catch((error)=>{
      console.log(error);
      ctx.setBusy(false);
    })
  }

  useEffect(()=>{
    loadSectionAnswer(ctx.testTaker.lastVisitedSection);
  },[])

  const loadSectionAnswer=(sectionNumber:number)=>{
    setCurrentSection(sectionNumber);
    if(ctx.test.testSections && ctx.test.testSections[sectionNumber]){
      ctx.setBusy(true);
      let resList:any = {};
      ctx.test.testSections[sectionNumber].items.forEach((question:any) => {
        resList[question.questionCode]=new AnswerModel();
      });
      HTTP.POST(endpoints.getAnswerList,{
        testId: ctx.testTaker['testId'],
        testTakerId: `${ctx.testTaker["id"]}`,
        scheduleId: ctx.testTaker["scheduleId"],
        sectionNumber: sectionNumber,
      }).then((response)=>{ 
        if(response.status===200){
          response.data.forEach((res:any) => {
            resList[res.questionCode]=res;
          });
          console.log(">> >> ",resList);
          setAnswers(resList);
          setForce(force+1);
        }else{
        }
        ctx.setBusy(false);
      }).catch((error)=>{
        console.log(error);
        setTotalTime(ctx.test.testSections[currentSection].timeLimit);
        ctx.setBusy(false);
      })
    }
  }

  useEffect(()=>{
    if(ctx.test.testSections) {
      setCurrentTime(ctx.testTaker.lastVisitedSection===(currentSection)?ctx.testTaker.lastVisitedSectionTime:0);
      setTotalTime(ctx.test.testSections[currentSection].timeLimit-(ctx.testTaker.lastVisitedSection===(currentSection)?ctx.testTaker.lastVisitedSectionTime:0));
    }
    let testTaker = ctx.testTaker;
    testTaker.lastVisitedSection=currentSection;
    ctx.setTestTaker(testTaker);
  },[currentSection])

  return ctx.busy?
    <></>:
    <div className="">
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-1 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <img src="/images/nxbas.avif" className="h-12" alt="NxBAS Logo" />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 items-center text-center">
              <p className="text-sm sm:mr-6 uppercase">ID : {ctx.user["username"]}</p>
              {
                totalTime>0 && <Qtimer key={`QT${totalTime}`} onLow={() => {}} 
                  seconds={totalTime} onFinish={() => {saveAnswers([],"timeout")}}
                  onEach={(tick) => {setCurrentTime(currentTime+1); if((tick%11)===0) saveAnswers([],"save");}}
                ></Qtimer> 
              }
            </div> 
          </div>
        </div>
      </nav>
      <SideBar></SideBar>
      <div className="md:p-4 my-20">
        <h1 className="text-center font-bold text-[2rem] mb-5">{t("section")} {currentSection+1}</h1>
        <div className="w-[96%] ml-[2%] md:w-[80%] md:ml-[10%] px-5" key={`DIV-0-${force}`}>
          {
            ctx.test.testSections && ctx.test.testSections[currentSection].items && 
            ctx.test.testSections[currentSection].items.map((question:any)=><div className="my-8 bg-gray-50 rounded-md p-4">
              <QuestionHandler
                onError={(error:ErrorModel)=>{console.log(error)}}
                onAnswer={(answer: AnswerModel) => { 
                  if(answer){
                    answer.questionType = question.type;
                    answer.questionPosition = question.position;
                    answer.sectionNumber = question.sectionNumber-1;
                    answer.spendTime = 0;
                    answer.maxRetry = 0;
                    answer.isMarked = false;
                    saveAnswers([answer]);
                    setAnswers({...answers,[question.questionCode]:answer});  
                  }
                }}
                data={{...question,"position":question.position,"answer":answers[question.questionCode]}}
                key={`qus${question.questionCode}${ctx.busy.toString()}`}
              ></QuestionHandler>
            </div>)
          }
          <div className="hcenter mt-20">
            {
              ctx.test.testSections!=null?
              <button
                disabled={ctx.test.testSections==null || Object.values(answers).filter((answer:any) => answer && answer.questionCode && (answer.answerData || answer.answerId)).length!==ctx.test.testSections[currentSection].items.length}
                className="mb-32 disabled:bg-gray-300 bg-blue-500 text-white p-2 px-10 rounded-md"
                onClick={() => {
                  saveAnswers(Object.values(answers),ctx.test.testSections.length===(currentSection+1)?"finish":"next");
                }}>
                {t(ctx.test.testSections.length===(currentSection+1)?"finish":"next")}
              </button>:<></>
            }
          </div>
        </div>
      </div>
    </div>;
}


export default TestTakingQuestionLIstVIew;

