import React, {
  useEffect,
  useState,
} from 'react';

import { AnswerModel } from '../../model/answer_model';
import { Item4 } from '../../model/test_model';

const QuestionWithImageMC = (props: any) => {
  const data: Item4 = props.data;
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    props.onAnswer(
      new AnswerModel({
        questionCode: data.questionCode,
        answerId: option,
      })
    );
  };

  useEffect(() => {
    if (data.answer) {
      setSelectedOption(data.answer["answerId"]);
    }
  }, []);

  const startIndex: number = data.question.indexOf("{{{img/") + 7;

  const code: string = data.question.substring(
    startIndex,
    data.question.indexOf("}}}", startIndex)
  );

  const pdfUrlTemplate: string =
    "https://nxbas-prod.sgp1.cdn.digitaloceanspaces.com/question-assets/";

  const imageUrl: string = `${pdfUrlTemplate}${code}.png`;

  const regex = /{{{img\/(.*?)}}}/g;

  return (
    <div>
      <div className="audio-info"></div>
      <div className="mcq-questions flex justify-between ">
        <div key={data.questionCode}>
          {data["showPosition"] && data.questionNumber && <span className="inline mt-1">{data.questionNumber}.&nbsp;</span>}

          {data.renderedQuestion}
          {/* {data.question.split(regex).map((part, index) => (
            <React.Fragment key={index}>
              {index % 2 !== 0 && <img src={imageUrl} alt="image" />}
              {index % 2 === 0 && ( 
                <p
                  dangerouslySetInnerHTML={{ __html: part }}
                  className="inline"
                ></p>
              )}
            </React.Fragment>
          ))} */}

          <ul className="mt-3">
            {data.choices.map((choice: any) => (
              <li key={choice.id} className='py-1.5'>
                <input
                  type="radio"
                  id={choice.id}
                  className="p-1"
                  name={`question-${data.questionCode}`}
                  checked={selectedOption === choice.id}
                  onChange={() => handleOptionSelect(choice.id)}
                />
                <label className="pl-2 text-gray-800" htmlFor={choice.id}>
                  {choice.choice}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default QuestionWithImageMC;
