import {
  useContext,
  useEffect,
} from 'react';

import { initFlowbite } from 'flowbite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../../context_provider/user_provider';
import routingPath from '../../../routing/router_path';

function TutorialInstruction() {
    const navigate = useNavigate();
    const ctx:any = useContext(UserContext);
    const { t } = useTranslation();

    useEffect(()=>{
        initFlowbite();
    },[]);

    const nextSlide=()=>{
        const carouselEle = document.getElementById('next');
        carouselEle?.click();
    }
    const prevSlide=()=>{
        const carouselEle = document.getElementById('prev');
        carouselEle?.click();
    }

    return (
    <div className="">
        <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
            <div className="max-w-full flex flex-wrap items-center justify-between mx-auto p-4">
                <img src="/images/nxbas.avif" className="h-14" alt="NxBAS Logo" />
                <p className="text-sm sm:mr-6 uppercase">ID : {ctx.user['username']}</p>
            </div>
        </nav>

        <hr/>
        
        <div className="mt-5 md:mt-20 mr-5 md:mr-40" style={{"textAlignLast":"end"}}>
        <button onClick={()=>{ 
                navigate(routingPath.tutorialQuestion)
            }} className="mr-5 font-normal text-xs text-white border btnbluebg hover:bg-blue-300 border-blue-500 py-2 px-3 rounded-md">{t("skip_ins")}</button>
        </div>

        <div id="default-carousel" className="relative w-full p-10" data-carousel="static" >
            <button id='next' type="button" className="opacity-0" data-carousel-next></button>
            <button id='prev' type="button" className="opacity-0" data-carousel-prev></button>

            <div className="relative h-svh overflow-hidden rounded-lg md:h-96">
                <div className="hidden duration-700 ease-in-out" data-carousel-item>
                    <div className="max-w-4xl mx-auto block">
                        <p className="font-semibold text-2xl">{t("tutorial")}</p>
                        <br/>
                        <p className="text-sm mb-3 mt-5">{t("ttl_desc1")}</p>
                        <p className="text-sm">{t("ttl_desc2")}</p> 
                        <br/>
                        <div className="flex justify-center mt-16 mb-6">
                        <button onClick={nextSlide} type="button" className="focus:outline-none text-white btnbluebg hover:bg-blue-300 font-medium rounded-md text-xs px-5 py-2">{t("next")}</button>
                        </div>
                    </div>
                </div>

                <div className="hidden duration-700 ease-in-out" data-carousel-item>
                    <div className="max-w-4xl mx-auto block">
                        <p className="font-semibold text-2xl">{t("navigation")}</p>
                        <p className="text-sm mb-3 mt-5 font-medium">{t("move_for")}</p>
                        <p className="text-sm">{t("click_next")}</p>
                        <p className="text-sm mt-5">{t("next_to_continue")}</p>
                        <div className="flex justify-between mt-16 mb-6 ">
                            <button type="button" className="focus:outline-none text-white btnbluebg hover:bg-blue-300 font-medium rounded-md text-xs px-5 py-2" onClick={prevSlide}>{t("back")}</button>
                            <button type="button" id="next" className="focus:outline-none text-white btnbluebg hover:bg-blue-300 font-medium rounded-md text-xs px-5 py-2 md:mr-40" onClick={nextSlide}>{t("next")}</button>
                        </div>
                    </div> 
                </div> 

                <div className="hidden duration-700 ease-in-out" data-carousel-item>
                    <div className="max-w-4xl mx-auto block">
                        <div className="md:flex">
                            <div className="md:w-4/6">
                                <p className="text-xl font-semibold">{t("using_scroll_bar")}</p>
                                <p className='text-sm my-6'>{t("ttl_desc_2")}</p>
                            </div>
                            <div className="h-52 md:h-40 md:w-1/3 border-gray-200 rounded-md overflow-y-auto shadow">
                                <p className="text-xl font-semibold pl-5">{t("scroll_bar_demo")}</p>
                                <p className="text-sm text-justify p-2 m-2">{t("ttl_desc_1")}</p>
                            </div>
                        </div>  
                        <div className="flex justify-between mt-16 mb-6 ">
                            <button type="button" onClick={prevSlide} className="focus:outline-none text-white btnbluebg hover:bg-blue-300 font-medium rounded-md text-xs px-5 py-2">{t("back")}</button>
                            <button type="button" onClick={nextSlide} className="focus:outline-none text-white btnbluebg hover:bg-blue-300 font-medium rounded-md text-xs px-5 py-2 md:mr-40">{t("next")}</button>
                        </div>  
                    </div> 
                </div>

                <div className="hidden duration-700 ease-in-out" data-carousel-item>
                    <div className="max-w-4xl mx-auto block">
                        <p className="text-xl font-semibold">{t("timing")}</p>
                        <p className='text-sm my-6'>
                            <ol className="text-sm ps-1 mt-2 space-y-1 list-decimal list-inside">
                                <li>{t("slide_4_1")}</li>
                                <li>{t("slide_4_2")} <span style={{color:"orange"}}>{t("orange")}</span> {t("slide_4_2_1")}</li>
                                <li>{t("slide_4_2")} <span style={{color:"red"}}>{t("red")}</span> {t("when_5_left")}</li>
                                <li>{t("hourglass")} <span data-v-6a863c08="" className="hour_glass"></span>  {t("3_min_left")}</li>
                            </ol>
                        </p>

                        <p className="text-sm mt-5">{t("next_to_continue")}</p>
                        <div className="flex justify-between mt-16 mb-6 "> 
                            <button type="button" onClick={prevSlide} data-carousel-prev className="focus:outline-none text-white btnbluebg hover:bg-blue-300 font-medium rounded-md text-xs px-5 py-2">{t("back")}</button>
                            <button type="button" onClick={()=>{navigate(routingPath.tutorialQuestion)}} className="focus:outline-none text-white btnbluebg hover:bg-blue-300 font-medium rounded-md text-xs px-5 py-2 md:mr-40">{t("start")}</button>
                        </div>  
                    </div> 
                </div>

            </div>
        </div>
    </div>
    );
}
export default TutorialInstruction;
