import {
  useEffect,
  useState,
} from 'react';

import { AnswerModel } from '../../model/answer_model';
import { ErrorModel } from '../../model/error_model';
import { Item3 } from '../../model/test_model';
import MediaHandler from '../common/queston_media_handler';
import QuestionDragAndDrop from '../question_type/question_cloze_drag';
import QuestionClozeTable from '../question_type/question_cloze_table';
import QuestionClozeWrite from '../question_type/question_cloze_write';
import QuestionWithImageMC from '../question_type/question_ImageMC';
import QuestionListening from '../question_type/question_listening';
import QuestionLS from '../question_type/question_ls';
import QuestionMatching from '../question_type/question_matching';
import QuestionMultipleArticle from '../question_type/question_multi_article';
import QuestionOpenText from '../question_type/question_open_text';
import QuestionPassageMultiple
  from '../question_type/question_passage_multiple';
import QuestionPassage from '../question_type/question_PassageMC';
import QuestionSlider from '../question_type/question_slider';
import QuestionSliderImage from '../question_type/question_slider_Image';
import QuestionSpeakingV2 from '../question_type/question_speaking';
import QuestionWriting from '../question_type/question_Writing';

const QuestionHandler = (props: any) => {
  const [lastAnswer, setLastAnswer] = useState<any>();
  const [question, setquestion] = useState<Item3>(props.data);
  question.highlight = props.highlight;
  var questionElement: any;

  useEffect(()=>{
    props.onAnswer(lastAnswer);
  },[lastAnswer])

  const onAnswer = (answer: AnswerModel) => {
    setLastAnswer({
      ...lastAnswer,
      ...answer,
      maxRetry:(question.type=="SPEAK"?answer.maxRetry:((lastAnswer?.maxRetry)??0)),
    });
  }

  const onError = (error: ErrorModel) => {
    props.onError(error);
  };

  useEffect(()=>{
    setquestion({
      ...question,
      renderedQuestion:MediaHandler(question,(answer:AnswerModel)=>{setLastAnswer({...lastAnswer,...answer})})
    });
    setLastAnswer(question.answer);
  },[])

  switch (question.type) {
    case "CZ":
      questionElement = (<QuestionClozeWrite onAnswer={onAnswer} data={question}></QuestionClozeWrite>);
      break;

    case "DR":
      questionElement = (<QuestionDragAndDrop onAnswer={onAnswer} data={question}></QuestionDragAndDrop>);
      break;

    case "MT":
      questionElement = (
        <QuestionMatching
          onAnswer={onAnswer}
          data={question}
        ></QuestionMatching>
      );
      break;

    case "MA":
      questionElement = (
        <QuestionMultipleArticle
          onAnswer={onAnswer}
          data={question}
        ></QuestionMultipleArticle>
      );
      break;

    case "OT":
      questionElement = (
        <QuestionWriting onAnswer={onAnswer} data={question}></QuestionWriting>
      );
      break;

    case "OTA": 
    questionElement = (
      <QuestionWriting onAnswer={onAnswer} data={question}></QuestionWriting>
    );
    break;

    case "MC":
      if (question.question.includes("{{{img/CEAPT017}}}")) {
        questionElement = (
          <QuestionWithImageMC
            onAnswer={onAnswer}
            data={question}
          ></QuestionWithImageMC>
        );
      } else if (question.question.includes("{{{audio/LISTEN01}}}")) {
        questionElement = (
          <QuestionListening
            onAnswer={onAnswer}
            data={question}
          ></QuestionListening>
        );
      } else {
        questionElement = (
          <QuestionPassage
            onAnswer={onAnswer}
            data={question}
          ></QuestionPassage>
        );
      }
      break;

    case "SPEAK":
      questionElement = (
        <QuestionSpeakingV2 onAnswer={onAnswer} data={question}></QuestionSpeakingV2>
      );
      break;

    case "IL":
      questionElement = (
        <QuestionSlider onAnswer={onAnswer} data={question}></QuestionSlider>
      );
      break;

    case "IC":
      questionElement = (
        <QuestionSliderImage onAnswer={onAnswer} data={question}></QuestionSliderImage>
      );
      break;

    case "OD":
      questionElement = (
        <QuestionOpenText onAnswer={onAnswer} data={question} onError={onError}></QuestionOpenText>
      );
      break;

    case "LS":
      questionElement = (
        <QuestionLS onAnswer={onAnswer} data={question}></QuestionLS>
      );
      break;

    case "PAS":
      questionElement = (<QuestionPassageMultiple onAnswer={onAnswer} data={question}></QuestionPassageMultiple>);
      break;
            
    case "CZT":
      questionElement = (
        <QuestionClozeTable onAnswer={onAnswer} data={question} onError={onError}></QuestionClozeTable>
      );
      break;
  
    default:
      console.log("Question type > ",question);
      questionElement = <p>'{question.type}' TYPE QUESTION FOUND...</p>;
      break;
  }

  return (
    <>
      <div>{questionElement}</div>
    </>
  );
};

export default QuestionHandler;
