import React, { useState } from 'react';

const allowDrop = (ev: React.DragEvent<HTMLDivElement>) => {
  ev.preventDefault();
};

const drag = (ev: React.DragEvent<HTMLDivElement>) => {
  ev.dataTransfer.setData("text", ev.currentTarget.id);
};

const QuestionDragAndDrop = (props: any) => {
  const [droppedItems, setDroppedItems] = useState<string[]>([]);

  const drop = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    const data = ev.dataTransfer.getData("text");
    const draggedAnswer = document.getElementById(data);
    if (draggedAnswer) {
      ev.currentTarget.appendChild(draggedAnswer);
      setDroppedItems([...droppedItems, data]);
    }
  };

  interface Question {
    questionCode: string;
    question: string;
    answer: null | string;
    sectionNumber: number;
    questionNumber: number;
    page: null | string;
    position: number;
    type: string;
    firstTestQuestion: boolean;
    lastTestQuestion: boolean;
    firstQuestionInSection: boolean;
    lastQuestionInSection: boolean;
    firstTutorialQuestion: boolean;
    firstTutorialSliderQuestion: boolean;
    firstTutorialSliderQuestionWithDifferentSlider: boolean;
    firstTutorialImageChoiceQuestion: boolean;
    firstTutorialLikertOrMcqQuestion: boolean;
    firstTutorialEssayQuestion: boolean;
    firstTutorialSpeakingQuestion: boolean;
    firstTutorialListeningQuestion: boolean;
    firstTutorialPassageQuestion: boolean;
    headerQuestion: null | string;
    clozes: Cloze[];
    numOfClozes: number;
  }

  interface Cloze {
    code: string;
    cloze: string;
    position: number;
  }

  const testData: Question = {
    questionCode: "CLOZE001",
    question:
      "According to the report, {{{cloze/1}}} of the following statements is {{{cloze/2}}} likely to be true",
    answer: null,
    sectionNumber: 1,
    questionNumber: 1,
    page: null,
    position: 1,
    type: "CZ",
    firstTestQuestion: true,
    lastTestQuestion: false,
    firstQuestionInSection: true,
    lastQuestionInSection: false,
    firstTutorialQuestion: false,
    firstTutorialSliderQuestion: false,
    firstTutorialSliderQuestionWithDifferentSlider: false,
    firstTutorialImageChoiceQuestion: false,
    firstTutorialLikertOrMcqQuestion: false,
    firstTutorialEssayQuestion: false,
    firstTutorialSpeakingQuestion: false,
    firstTutorialListeningQuestion: false,
    firstTutorialPassageQuestion: false,
    headerQuestion: null,
    clozes: [
      {
        code: "CLOZE001CZ1",
        cloze: "which",
        position: 1,
      },
      {
        code: "CLOZE001CZ2",
        cloze: "is",
        position: 2,
      },
    ],
    numOfClozes: 2,
  };

  // Find the index of the cloze/1 placeholder in the question string
  const indexCloze1 = testData.question.indexOf("{{{cloze/1}}}");

  // Extract the parts of the question string before and after the cloze/1 placeholder
  const beforeCloze1 = testData.question.substring(0, indexCloze1);
  const afterCloze1 = testData.question.substring(
    indexCloze1 + "{{{cloze/1}}}".length
  );

  // Replace the cloze/1 placeholder with the input element JSX
  const questionWithInput1 = (
    <div>
      {beforeCloze1}
      <div
        id="second-box1"
        onDrop={drop}
        onDragOver={allowDrop}
        className="inline-block w-[100px] mx-1 h-8 border-2"
      ></div>
    </div>
  );

  // Find the index of the cloze/2 placeholder in the updated question string
  const indexCloze2 = afterCloze1.indexOf("{{{cloze/2}}}");

  // Extract the parts of the question string before and after the cloze/2 placeholder
  const beforeCloze2 = afterCloze1.substring(0, indexCloze2);
  const afterCloze2 = afterCloze1.substring(
    indexCloze2 + "{{{cloze/2}}}".length
  );

  // Replace the cloze/2 placeholder with the input element JSX
  const questionWithBothInputs = (
    <div>
      {beforeCloze2}
      <div
        id="second-box2"
        onDrop={drop}
        onDragOver={allowDrop}
        className="inline-block w-[100px] mx-1 h-8 border-2"
      ></div>
      {afterCloze2}
    </div>
  );

    return (
      <div>
        <h2>Drag and Drop Question</h2>
        <div id="article">
          <div
            id="answer"
            onDrop={drop}
            onDragOver={allowDrop}
            className="border-2 h-[40px] p-2 mb-4 flex gap-5"
          >
            {testData.clozes.map((item) => (
              <div key={item.code} id={item.code} draggable onDragStart={drag}>
                {item.cloze}
              </div>
            ))}
          </div>
          <p className="flex">
            <span className="inline">{questionWithInput1}</span>
            <span className="inline">{questionWithBothInputs}</span>
          </p>
        </div>
      </div>
    );
  };


export default QuestionDragAndDrop;
