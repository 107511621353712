
const mediaRenderer = (htmlStr:string) => {
    const regex = /{{{.{2,12}\/.{2,12}}}}/g;
    htmlStr.match(regex)?.forEach((match)=>{
        let regexData = match.replace(/[{,}]/g,"").split("/");
        let elementHtml = match;
        switch (regexData[0]) {
            case "pdf":
                elementHtml =  `<object class="pdfView" id="${regexData[1]}" data="https://docs.google.com/gview?embedded=true&url=https://nxbas-prod.sgp1.cdn.digitaloceanspaces.com/question-assets/${regexData[1]}.pdf">
                <p class="mt-[30%]" style="text-align-last:center;">PDF is loading...</p>
                </object>`;
                break;

            case "img":
                elementHtml =  `<img src="https://nxbas-prod.sgp1.cdn.digitaloceanspaces.com/question-assets/${regexData[1]}.png"/>`;
                break;

            case "audio":
                elementHtml =  `<audio 
                controls
                controlsList="nodownload" 
                className="w-full" 
                >
                <source key={index} src="https://nxbas-prod.sgp1.cdn.digitaloceanspaces.com/question-assets/${regexData[1]}.mp3" type="audio/mpeg" />
                </audio>`;
                break;

            default:
                console.log("Unhandeled pattern found >> ",regexData);
                break;
        }

        htmlStr = htmlStr.replace(match,elementHtml);
    });
    return htmlStr;
}

export default mediaRenderer;