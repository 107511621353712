import 'react-toastify/dist/ReactToastify.css';

import {
  HashRouter,
  Route,
  Routes,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { UserProvider } from '../context_provider/user_provider';
import DemographicFinish from '../screen/test/demographic/demographic_finish';
import DemographicInstruction
  from '../screen/test/demographic/demographic_Instruction';
import DemographicQuestion
  from '../screen/test/demographic/demographic_questions';
import TestTakingFinish from '../screen/test/finish/test_taking_finish';
import TestTakingTimeOut from '../screen/test/finish/test_taking_time_out';
import DPA from '../screen/test/test_setup/data_agreement';
import StartTestSetup from '../screen/test/test_setup/start_test_setup';
import TestTakingInstruction
  from '../screen/test/test_taking/test_taking_Instruction';
import TestTakingQuestionLIstVIew
  from '../screen/test/test_taking/test_taking_questions_list_view';
import TestTakingQuestion
  from '../screen/test/test_taking/test_taking_questions_single_view';
import TutorialFinish from '../screen/test/tutorial/tutorial_finish';
import TutorialInstruction from '../screen/test/tutorial/tutorial_Instruction';
import TutorialQuestion from '../screen/test/tutorial/tutorial_questions';
import VerificationUser from '../screen/test/verification/user_verification';
import NotFound from '../utils/ui/not_found';
import routingPath from './router_path';

var routing = (
  <HashRouter>
    <UserProvider>
      <Routes>
          <Route path={routingPath.start} Component={StartTestSetup} />
          <Route path={routingPath.notFound} Component={NotFound} />                

          <Route path={routingPath.dpa} Component={DPA} />

          <Route path={routingPath.tutorialInstruction} Component={TutorialInstruction} />
          <Route path={routingPath.tutorialQuestion} Component={TutorialQuestion} />
          <Route path={routingPath.tutorialFinish} Component={TutorialFinish} />

          <Route path={routingPath.demographicInstruction} Component={DemographicInstruction} />
          <Route path={routingPath.demographicQuestion} Component={DemographicQuestion} />
          <Route path={routingPath.demographicFinish} Component={DemographicFinish} />

          <Route path={routingPath.testTakingInstruction} Component={TestTakingInstruction} />
          <Route path={routingPath.testTakingQuestion} Component={TestTakingQuestion} />
          <Route path={routingPath.testTakingQuestionList} Component={TestTakingQuestionLIstVIew} />

          <Route path={routingPath.testTakingFinish} Component={TestTakingFinish} />
          <Route path={routingPath.TestTakingTimeOut} Component={TestTakingTimeOut} />

          <Route path={routingPath.verificationUser} Component={VerificationUser} />

      </Routes>
      <ToastContainer limit={4} position="bottom-right"/>
    </UserProvider>
  </HashRouter>
);

export default routing;