import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { logoutButton } from '../../../components/common/page_components';
import { UserContext } from '../../../context_provider/user_provider';

function TestTakingTimeOut() {
    const ctx:any = useContext(UserContext);
    const { t } = useTranslation();

    return (
        <div className="">
            <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
                <div className="max-w-full flex flex-wrap items-center justify-between mx-auto p-4">
                    <img src="/images/nxbas.avif" className="h-14" alt="NxBAS Logo" />
                    <p className="text-sm sm:mr-6 uppercase">ID : {ctx.user['username']}</p>
                </div>
            </nav>
            <hr/><br/><br/><br/><br/>
            <div className="w-full text-center mt-36">
                <div className="text-md text-center">
                    <p>{t("time_out_msg")}</p>
                    <p dangerouslySetInnerHTML={{
                        __html:(ctx.test['translations'])? ctx.test['translations']['testSectionTranslations'][ctx.Qsection]['endOfSectionInstruction']:''
                    }}></p>
                </div>
            </div>
            <br />
            <br />
            <div style={{textAlignLast:'center'}}>
                <div className="mb-5">{logoutButton(ctx)}</div>
            </div>
        </div>
    );

}

export default TestTakingTimeOut;