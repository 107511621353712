import React, {
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { AnswerModel } from '../../model/answer_model';
import { Item4 } from '../../model/test_model';

const QuestionMatching = (props: any) => {
  const { t, i18n } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<
      Record<string, string>
  >({});
  var data: Item4 = props.data;

  useEffect(()=>{
    data.matches.forEach((item:any) => {selectedOptions[item.code]=""});
    if(data['answer'] && data['answer']['answerData'] && data['answer']['answerData']['subData']) {
      data['answer']['answerData']['subData'].forEach((answerItem:any) => {
        selectedOptions[answerItem['questionCode']]=answerItem['answerCode'];
      });
    }
    setSelectedOptions(selectedOptions);
  },[])


  const handleOptionSelect = (leftItems: string, option: string) => {
    setSelectedOptions((prevOptions: Record<string, string>) => {
      const updatedOptions = {
        ...prevOptions,
        [leftItems]: option,
      };

      const values = Object.values(updatedOptions);
      const allOptionsSelected =
        values.filter((value) => value !== "").length ===
        data.matches.length;
        const answerModel = new AnswerModel({
          questionCode: data.questionCode,
          answerData: {
            subData: Object.keys(updatedOptions).map((key:any)=>({
                questionCode: key,
                answerCode: updatedOptions[key],
            }))
          },
          isMarked: false,
        });

        if (!allOptionsSelected) {
          answerModel.isAnswered = false;
        }
        props.onAnswer(answerModel);
        return updatedOptions;
    });
  };

  return (
      <div>
      {data["showPosition"] && data.questionNumber && <span className="inline mt-1">{data.questionNumber}.&nbsp;</span>}
      {data.renderedQuestion}
        {/* <span
            className="inline mb-2"
            dangerouslySetInnerHTML={{ __html: data.question }}
        ></span> */}

        <div className="matching-container flex mt-5">
          <div className="left-items">
            {data.matches.map((match: any) => (
                <div key={match.code} className="matching-item mb-10">
                  <div className="flex">
                    <span className="left-mark">{match.matchKey}</span>
                    <div className="w-[10rem] md:w-[20rem] border-t mt-3 mx-2 border-gray-200"></div>
                  </div>
                </div>
            ))}
          </div>

          <div className="right-items">
            {data.matches.map((match: any) => (
                <div key={match.code} className="matching-item">
                  <select
                    value={selectedOptions[match.code]}
                    onChange={(e) =>handleOptionSelect(match.code, e.target.value)}
                    className="matching-dropdown text-sm rounded-md mb-5">
                    <option value="">{t("select_match")}</option>
                      {data.matches.map((option:any) => (
                        <option key={option.matchValue} value={option.matchValue}>
                          {option.matchValue}
                        </option>
                      ))}
                  </select>
                </div>
            ))}
          </div>
          
        </div>
      </div>
  );
};

export default QuestionMatching;
