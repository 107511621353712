import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context_provider/user_provider';
import { AnswerModel } from '../../model/answer_model';
import {
  ErrorModel,
  ErrorTypes,
} from '../../model/error_model';
import { Item3 } from '../../model/test_model';

const QuestionOpenText = (props: any) => {
  const { t } = useTranslation();
  var data: Item3 = props.data;
  const ctx: any = useContext(UserContext);
  const [answer, setAnswer] = useState(
    data.response ? data.response.freeTextResponse : ""
  );
  const [error, setError] = useState("");
  var answers: any = {};
  var timer: any;

  const onAnswer = (e: any) => {
    let value = e.target.value;
    if(data.condition && value){
      try {
        if(data.condition.maxValue!==undefined && parseFloat(value)>data.condition.maxValue){
          props.onAnswer(new AnswerModel());
          props.onError(new ErrorModel({message:t("max_limit",{value:data.condition.maxValue}),type:ErrorTypes.Error}));
          return;
        }
        if(data.condition.minValue!==undefined && parseFloat(value)<data.condition.minValue){
          props.onAnswer(new AnswerModel());
          props.onError(new ErrorModel({message:t("min_limit",{value:data.condition.minValue}),type:ErrorTypes.Error}));
          return;
        }
      } catch (error) {
        console.log("Validation error : ",error);
      }
    }
    setError(``);
    props.onError(null);
    clearTimeout(timer);
    clearInterval(timer);
    timer=undefined;
    timer = setTimeout(()=>{
      try {
        answers[e.target.id] = value;
        if (Object.values(answers).filter((e) => e === "").length > 0) {
          props.onAnswer(new AnswerModel());
        } else {
          const answerModel = new AnswerModel({
            questionCode: data.questionCode,
            answerData: { alphaData: value },
          });
          props.onAnswer(answerModel);
        }
      } catch (error) {
      }
    },500)
  };

  useEffect(() => {
    if (
      data["answer"] &&
      data["answer"]["answerData"] &&
      data["answer"]["answerData"]["alphaData"]
    ) {
      setAnswer(data["answer"]["answerData"]["alphaData"]);
    }
  }, []);

  return (
    <div className="">
      {data["showPosition"] && data.questionNumber && <span className="inline mt-1">{data.questionNumber}.&nbsp;</span>}
      {data.renderedQuestion}
      {/* <span
        className="inline mb-2"
        dangerouslySetInnerHTML={{ __html: data.question }}></span> */}
      <input
        className="rounded-md mt-2 w-full p-2 border border-gray-600"
        type={data.type === "OD" ? "number" : "text"}
        onInput={onAnswer}
        onKeyDown={(e)=>{if(((e.keyCode<48 && e.keyCode!==8) || e.keyCode>57) && data.type === "OD") e.preventDefault()}}
        // onKeyDown={(e)=>{console.log(e.keyCode); if(e.keyCode===69 && data.type === "OD") e.preventDefault()}}
        defaultValue={answer}
        min={
          data.validationCondition 
            ? (data.validationCondition["min_value"] || (data.condition ? data.condition["minValue"] : 1))
            : 1
        }
        max={
          data.validationCondition
            ? (data.validationCondition["max_value"] || (data.condition ? data.condition["maxValue"] : 1))
            : undefined
        }
        placeholder={ctx.t("enter_here")}
      ></input>
      {/* <p>{data.condition.maxValue && parseFloat(answer)}-{data.condition.maxValue}</p> */}
      <small className="text-xs text-red-500">{error}</small>
    </div>
  );
};
export default QuestionOpenText;
