import axios from 'axios';

import { goToCallback } from '../../components/common/page_components';
import ApiResponseModel from '../../model/api_response';

function HTTP () {}
HTTP.isDevelopment = false;
HTTP.API_URL = HTTP.isDevelopment? "/api/":`${process.env.REACT_APP_API_BASE_URL}/`;

HTTP.GET = async (endpoint:string):Promise<ApiResponseModel> => {
    console.log("GET Request # ",endpoint);
    try {
        let result = await axios.get(endpoint );
        console.log("GET Response # ",result.data);
        HTTP.SESSIONSTATUS(result.status);
        return new ApiResponseModel({status:result.status,data:result.data,msg:result.statusText});
    } catch (error) {
        return new ApiResponseModel({status:500,data:undefined,msg:"Something went wrong!"});
    }
}

HTTP.POST = async (endpoint:string, payload:any):Promise<ApiResponseModel> => {
    console.log("POST Request # ",endpoint,payload);
    try {
        let result = await axios.post(endpoint,payload );
        console.log("POST Response # ",result.data);
        HTTP.SESSIONSTATUS(result.status);
        return new ApiResponseModel({status:result.status,data:result.data,msg:result.statusText});
    } catch (error) {
        return new ApiResponseModel({status:500,data:undefined,msg:"Something went wrong!"});
    }
}

HTTP.MULTIMEDIA = async (endpoint:string, payload:any):Promise<ApiResponseModel> => {
    console.log(endpoint,payload);
    try {
        let result = await axios.post(endpoint,payload);
        HTTP.SESSIONSTATUS(result.status);
        return new ApiResponseModel({status:result.status,data:result.data,msg:result.statusText});
    } catch (error) {
        return new ApiResponseModel({status:500,data:undefined,msg:"Something went wrong!"});
    }
}

HTTP.SESSIONSTATUS = async (status:number) => {
    if(status===403) {
        goToCallback();
        alert("Your session is out. Try to login again");
    }
}

export default HTTP;