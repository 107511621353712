import React from 'react';

function NotFound() {
  return (
    <div className="NotFound absolute bottom-2/4 w-screen">
      <p className="text-center text-3xl font-semibold opacity-80">Not found !</p>
      <p className="text-center text-xs">Something went wrong. Try to refresh...</p>
    </div>
  );
}
export default NotFound;
