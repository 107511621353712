import {
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

const Qtimer=({onFinish=()=>{},onLow=()=>{},onEach=(tick:number)=>{},seconds=0,highlight=""})=>{
    const [count, setCount] = useState(seconds)
    const { t } = useTranslation();

    useEffect(() => {
        if(count>0){
            const interval = setTimeout(() => {
                onEach(seconds-count-1);
                setCount(count - 1);
                if(count<=20){onLow();}
            }, 1000);
            return () => clearTimeout(interval);
        }else if(count<=0){
            onFinish();
        }
    }, [count]);
    
    
    return (
        <div className={`border rounded-lg flex p-1 px-2 items-center ${highlight==="tutorial"?'shadow_animation':''} ${count<300?'red_animation bg-red-500 shadow-red-500 shadow-lg':count<600?'bg-orange-400 shadow-orange-500 shadow-lg':'shadow-md'}`}>
            <table align="center" className="text-center">
                <tr>
                    <td className="text-md">{Math.floor(count/3600).toString().padStart(2,'0')}</td>
                    <td className="text-md">{Math.floor((count%3600)/60).toString().padStart(2,'0')}</td>
                    <td className="text-md">{Math.floor((count%3600)%60).toString().padStart(2,'0')}</td>
                </tr>
                <tr>
                    <td className="text-xs">{t("hour")}</td>
                    <td className="text-xs px-2">{t("minute")}</td>
                    <td className="text-xs">{t("second")}</td>
                </tr>
            </table>
            {count<180 &&  <div className="mb-2 ml-2"><span data-v-6a863c08="" className="hour_glass"></span></div>}
        </div>
    );
}

export default Qtimer;