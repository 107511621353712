import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { initFlowbite } from 'flowbite';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Webcam from 'react-webcam';

import { deniedPermission } from '../../../components/common/page_components';
import { UserContext } from '../../../context_provider/user_provider';
import endpoints from '../../../data/network/API_ENDPOINT';
import HTTP from '../../../data/network/HTTP';
import routingPath from '../../../routing/router_path';

function VerificationUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const ctx: any = useContext(UserContext);
  const { t } = useTranslation();
  const webcamRef = useRef<Webcam>(null);
  const [image, setImage] = useState<any>();
  const [busy,] = useState(false);
  const [captured, setCaptured] = useState(false);
  const permissionStates = { 
    dismissed:"dismissed",
    denied:"denied",
    allow:"allow"
  };
  const [hasPermission, setHasPermission] = useState(permissionStates.allow);

  const videoConstraints = {
    width: 300,
    height: 300,
    facingMode: "user",
  };

  const capture = () => {
    if (webcamRef && webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc);
      setCaptured(true);
    }
  };

  const retake = () => {
    setCaptured(false);
    setImage(null);
  };

  useEffect(() => {
    initFlowbite();
    navigator.mediaDevices.getUserMedia({video:true})
    .then((permissionObj) => {
      setHasPermission(permissionObj.active?permissionStates.allow:permissionStates.denied);
    }) 
    .catch((error) => {
      console.error(error);
      if(error.toString().indexOf(permissionStates.dismissed)>=0){
        setHasPermission(permissionStates.dismissed);
      }else{
        setHasPermission(permissionStates.denied);
      }
    });
  }, []);

  const submitPicture = () => {
    if (image) {
      fetch(image)
        .then((res) => res.blob())
        .then((blob) => {
          const imageFile = new File([blob], "image.png", {
            type: "image/png",
          });

          var formData = new FormData();
          formData.append("file", imageFile);
          formData.append("testTakerId", ctx.testTaker["id"]);

          fetch(`${HTTP.API_URL}/${endpoints.submitPicture}`, {
            method: "POST",
            body: formData,
            headers: {
              Authorization:`Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((r) => {
            HTTP.SESSIONSTATUS(r.status);
            if (r.status === 200) {
              navigate(routingPath.testTakingInstruction);
            }
          }).catch((data) => {
            console.error(data);
          });
        })
        .catch((error) => {
          console.error("Error fetching image data:", error);
        });
    }
  };

  return (
    <div>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-1 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <img src="/images/nxbas.avif" className="h-12" alt="NxBAS Logo" />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 items-center text-center">
              <p className="text-sm sm:mr-6 uppercase">
                ID : {ctx.user["username"]}
              </p>
            </div>
          </div>
        </div>
      </nav>
      <div className="md:p-4">
        <div className="md:p-4 my-20">
          <div className="overflow-y-auto">
            <div className="webkit-center mx-5 md:mx-20 lg:mx-30 xl:mx-[30%] ">
              <div className="max-w-full flex flex-wrap items-center justify-between md:p-4">
                <div className="text-left"> 
                  <div className="max-w-full flex flex-wrap items-center justify-between">
                    <p className="text-xl font-bold">{t("ver_req")}</p>
                    <button
                      onClick={() => {
                        navigate(routingPath.testTakingInstruction);
                      }}
                      className="font-normal text-xs text-white  bg-blue-500 border border-blue-500 py-2 px-3 rounded-md"
                    >
                      {t("skip")}
                    </button>
                  </div>
                  <p className="text-xl font-bold">{t("ver_ins")}:</p>
                  <ol className="mt-5 ml-5 text-md">
                    <li className='flex'>1. <p className='ml-2'>{t("ver_desc_1")}</p> </li>
                    <li className='flex'>2. <p className='ml-2'>{t("ver_desc_2")}</p> </li>
                    <li className='flex'>3. <p className='ml-2'>{t("ver_desc_3")}</p> </li>
                    <li className='flex'>4. <p className='ml-2'>{t("ver_desc_4")}</p> </li>
                  </ol>
                </div>
              </div>
              <br />
              <br />
              {image ? (
                <div>
                  <div className="w-full py-3 webkit-center">
                    <img className="rounded-md" src={image} alt="User..." />
                  </div>
                </div>
              ) : (
                <Webcam
                  className="rounded-md shadow-md shadow-slate-400"
                  width={300}
                  audio={false}
                  height={300}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  screenshotQuality={80}
                  videoConstraints={videoConstraints}
                />
              )}
              <br />
              {
                hasPermission!==permissionStates.allow?
                <div className="text-sm">
                {
                  hasPermission===permissionStates.dismissed?
                  <div>
                    <p>{t("permission_dissmiss")}</p>
                    <button 
                      className="bg-gray-400 text-white rounded-md py-1.5 px-4 mt-3"
                      onClick={()=>{
                        navigator.mediaDevices.getUserMedia({video:true}).then(r=>{}).catch(r=>{});
                      }}
                    >{t("try_again")}</button>
                  </div>
                  : deniedPermission(t)
                }</div>:
                (busy ? (
                <button className="align-text-bottom webkit-center bg-transparent font-semibold text-white rounded-md pb-0 pt-0 px-10">
                  <svg
                    aria-hidden="true"
                    className="w-7 h-7 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </button>
              ) : (
                <div>
                  {!captured && (
                    <button
                      onClick={capture}
                      type="button"
                      className="bg-gray-400 text-white rounded-md py-1.5 px-4"
                    >
                      {t("ver_take_photo")}
                    </button>
                  )}
                  {captured && (
                    <div className="">
                      <div className="mb-2">
                        <button
                          type="button"
                          className=" bg-gray-500 font-semibold text-white rounded-md py-1.5 px-4"
                          onClick={retake}
                        >
                          {t("retake")}
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={submitPicture}
                          type="button"
                          className="bg-green-500 font-semibold text-white rounded-md py-1.5 px-4"
                        >
                          {t("next")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
      <div
        id="default-modal"
        tabIndex={-1}
        aria-hidden="false"
        className="hidden shadow-md overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {t("ver_alert_title")}
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="default-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="text-center p-4 md:p-5 space-y-3">
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                {t("ver_alert_desc")}
              </p>
              <div className="w-full py-3 webkit-center ">
                <img className="rounded-md" src={image} alt="User..." />
              </div>
            </div>

            <div
              style={{ textAlignLast: "end" }}
              className="p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600"
            >
              <button
                data-modal-hide="default-modal"
                type="button"
                className="mr-3 bg-gray-500 font-semibold text-white rounded-md py-2 px-5"
              >
                {t("retake")}
              </button>
              <button
                data-modal-hide="default-modal"
                onClick={submitPicture}
                type="button"
                className="bg-red-500 font-semibold text-white rounded-md py-2 px-6"
              >
                {t("submit")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
export default VerificationUser;
