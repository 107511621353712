import React, {
  useEffect,
  useState,
} from 'react';

import { AnswerModel } from '../../model/answer_model';
import mediaRenderer from '../common/queston_media_rendering';

const QuestionPassage = (props: any) => {
  var data:any = props.data;
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    const answerModel = new AnswerModel({
      questionCode: data.questionCode,
      answerId:option,
      isMarked: false,
    });
    props.onAnswer(answerModel);
  };

  useEffect(() => {
    if (data && data.answer && data.answer.answerId) {
      setSelectedOption(data.answer.answerId);
    } 
  }, []);

  return ( 
    <div>
      <div className="audio-info w-96"></div>
      <div className="mcq-questions flex justify-between ">
        <div key={data.questionCode}>
        {data["showPosition"] && data.questionNumber && <span className="inline mt-1">{data.questionNumber}.&nbsp;</span>}
        {data.renderedQuestion}
          {/* <span
            className="inline"
            dangerouslySetInnerHTML={{ __html: data.question }}
          ></span> */}
          <ul className="mt-5 text-left">
            {data.choices.map((choice: any,index:number) => (
              <li key={choice.id}>
                <input
                  type="radio"
                  id={choice.id}
                  className="p-1 my-2"
                  name={`question-${data.questionCode}`}
                  checked={selectedOption === choice.id}
                  onChange={() => handleOptionSelect(choice.id)}
                />
                <label className="pl-2 text-gray-800" htmlFor={choice.id}>
                  <span className="mr-1">  {String.fromCharCode(65+index)}. </span>
                  <span dangerouslySetInnerHTML={{__html:mediaRenderer(choice.choice)}}></span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default QuestionPassage;
