import { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../../context_provider/user_provider';
import routingPath from '../../../routing/router_path';

function TestTakingInstruction() {
  const navigate = useNavigate(); 
  const ctx: any = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <div className="">
      <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
        <div className="max-w-full flex flex-wrap items-center justify-between mx-auto p-4">
          <img src="/images/nxbas.avif" className="h-14" alt="NxBAS Logo" />
          <p className="text-sm sm:mr-6 uppercase">
            ID : {ctx.user["username"]}
          </p>
        </div>
      </nav>
      <hr />
      <br />
      <br />
      <br />
      <br />
      <div className="w-full text-center mt-30">
        <h1 className="text-center font-bold text-[2rem] mb-5">{t("assess_instruction")}</h1>
        <div className="text-start text-md text-gray-800 max-w-md lg:max-w-xl flex flex-wrap items-center justify-between mx-5 md:mx-auto pt-10">
          <span dangerouslySetInnerHTML={{__html:ctx.test['instruction']}} ></span>
          <p className="p-7 pt-10">
            {t("click")}{" "}
            <span className="text-primary">{t("start_assessment")}</span>{" "}
            {t("to_take")}
          </p>
        </div>
        <br />
        <br />
        <br />
        <button
          onClick={()=>{
            if(ctx.test.singlePageType){
              navigate(routingPath.testTakingQuestionList);
            }else{
              navigate(routingPath.testTakingQuestion);
            }
          }}
          className="m-5 bg-blue-500 text-white p-2 px-8 rounded-md">
          {t("tt_ins_start")}
        </button>
      </div>
    </div>
  );
}

export default TestTakingInstruction;
