import React, {
  useEffect,
  useState,
} from 'react';

import { AnswerModel } from '../../model/answer_model';
import {
  Item3,
  MarksMap2,
} from '../../model/test_model';

const QuestionSlider = (props: any) => {
  var data: Item3 = props.data;
  const [choosed, setChoosed] = useState(""); 
  const [position, _setposition] = useState(0); 


  const setposition = (side: string, value: number) => {
    setChoosed(side);
    _setposition(value);

    if(data.scaleMin && value<=data.scaleMin){
      props.onAnswer(new AnswerModel());
      return;
    }

    if (side !== "" && value > 0) {
      const answerModel = new AnswerModel({
        questionCode: data.questionCode,
        isMarked: false,
        answerData:{
          alphaData: side,
          numericData: value,
        } 
      });
      props.onAnswer(answerModel); 
    }else{
      props.onAnswer(new AnswerModel());
    }
  };

  useEffect(() => {
    if(data['answer'] && data['answer']['answerData'] && data['answer']['answerData']['numericData']){
      setChoosed(data['answer']['answerData']['alphaData']);
      _setposition(data['answer']['answerData']['numericData']) 
    }
  }, []);

  const imgUrlLeft = `https://nxbas-prod.sgp1.cdn.digitaloceanspaces.com/question-assets/${data.leftPairImage}.png`;
  const imgUrlRight = `https://nxbas-prod.sgp1.cdn.digitaloceanspaces.com/question-assets/${data.rightPairImage}.png`;


  return (
    <div className="text-left mb-10">
      {/* <p>{choosed}-{position}</p> */}
      {data["showPosition"] && data.questionNumber && <span className="inline mt-1">{data.questionNumber}.&nbsp;</span>}
      {data.renderedQuestion}
      {/* <span
        className="inline pb-5"
        dangerouslySetInnerHTML={{ __html: data.question }}
      ></span> */}
      <div className="grid grid-cols-2 mt-10">
        <div key="QUS-1" className="pr-5">
          {data.leftPairImage !== null && data.leftPairImage !== "" ? (
            <img
              className="w-40 mb-6 rounded-md shadow-lg"
              src={imgUrlLeft}
              alt="no img..."
            ></img>
          ) : (
            <></>
          )}
          <div className={`flex items-start ${choosed === "" && data.highlight === "tutorial"? "shadow_animation rounded-md p-2" : ""}`}>
            <input name="chk" checked={choosed === "left"} onChange={(e) => { setposition("left", 0); }} 
            type="checkbox" className="mr-2 rounded-full" style={{ marginTop: 2 }}></input>
            <p className="text-sm h-28 text-justify text-ellipsis whitespace-normal overflow-hidden md:h-16">{data.leftPairImageText}</p>
          </div>
          <input
            id="default-range"
            type="range"
            style={{
              backgroundImage:
                choosed === "left"
                ? `linear-gradient(90deg, skyblue ${
                    (position / data.scaleMax) * 100
                  }%, transparent 0%)`
                : "",
            }}
            value={choosed === "left" ? position : 0}
            onChange={(e) => {
              setposition("left", parseInt(e.target.value));
            }}
            max={data.scaleMax}
            min={data.scaleMin}
            className={`w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700
                      ${
                        choosed !== "" && data.highlight === "tutorial"
                          ? "shadow_animation"
                          : ""
                      }
                    `}
          />
          <div className="max-w-full text-xs font-semibold flex flex-wrap items-center justify-between mx-2 py-2">
            {data.marksMap?.map((ele: MarksMap2, index: number) => (
              <span
                key={`OPTR-${index}`}
                dangerouslySetInnerHTML={{ __html: ele.label }}
              ></span>
            ))}
          </div>
        </div>
        <div key="QUS-2" className="pl-5">
          {data.rightPairImage !== null && data.rightPairImage !== "" ? (
            <img
              className="w-40 mb-6 rounded-md shadow-lg"
              src={imgUrlRight}
              alt="no img..."
            ></img>
          ) : (
            <></>
          )}
          <div
            className={`flex items-start ${
              choosed === "" && data.highlight === "tutorial"
              ? "shadow_animation rounded-md p-2"
              : ""
            }`}
          >
            <input
              name="chk"
              checked={choosed === "right"}
              onChange={(e) => {
                setposition("right", 0);
              }}
              type="checkbox"
              className="mr-2 rounded-full"
              style={{ marginTop: 2 }}
            ></input>
            <p className="text-sm h-28 text-justify text-ellipsis whitespace-normal overflow-hidden md:h-16">{data.rightPairImageText}</p>
          </div>
          <input
            id="default-range"
            type="range"
            onChange={(e) => {
              setposition("right", parseInt(e.target.value));
            }}
            style={{
              backgroundImage:
                choosed === "right"
                  ? `linear-gradient(90deg, skyblue ${
                      (position * 100) / data.scaleMax
                    }%, transparent 0%)`
                  : "",
            }}
            value={choosed === "right" ? position : 0}
            max={data.scaleMax}
            min={data.scaleMin}
            className={`w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700
              ${choosed !== "" && data.highlight === "tutorial" ? "shadow_animation" : ""}
            `}/>
          <div className="max-w-full text-xs font-semibold flex flex-wrap items-center justify-between mx-2 py-2">
            {data.marksMap?.map((ele: MarksMap2, index: number) => (
              <span
                key={`OPTR-${index}`}
                dangerouslySetInnerHTML={{ __html: ele.label }}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default QuestionSlider;
