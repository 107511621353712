import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { Item3 } from '../../model/test_model';

const mediaPattern = /({{{[a-zA-Z]{1,20}\/[a-zA-Z0-9]{1,20}}}})/g;
const assetUrl = (fileName:string)=> `https://nxbas-prod.sgp1.cdn.digitaloceanspaces.com/question-assets/${fileName}`;
const pdfAssetUrl = (fileName:string) =>  assetUrl(`${fileName}.pdf`);
const audioAssetUrl = (fileName:string) => assetUrl(`${fileName}.mp3`);
const imgAssetUrl = (fileName:string) => assetUrl(`${fileName}.png`);

interface audioHandlerOption {
    file:string
    data:Item3
    onAnswer:Function
}
const AudioHandler = ({file,data,onAnswer}:audioHandlerOption) => {
    const { t } = useTranslation();
    const [attempt, setAttempt] = useState<number>(data.answer && data.answer.maxRetry ? data.answer.maxRetry:0);
    const [playing, setPlaying] = useState(false);
    const audioRef = useRef<HTMLAudioElement>(null);
    // const [coutner, setCoutner] = useState(0);
    const [counter, setCounter] = useState(0);
    const [counterTimer, setCounterTimer] = useState<any>(null);
    useEffect(()=>{
        if(localStorage.getItem(`${data.questionCode}-audio`)) {
            audioRef.current!.currentTime=(parseInt(localStorage.getItem(`${data.questionCode}-audio`)??"0")-20);
        }
        // document.addEventListener("keydown",(e)=>{
        //     if(e.keyCode==32){
        //     }
        // })
        return ()=>{clearInterval(counterTimer);}
    },[])

    return  <React.Fragment key={"test"}>
        <audio 
            onFocus={(e)=>{}}
            autoFocus={false}
            tabIndex={-1}
            ref={audioRef}
            onSeeking={(e)=>{
                if(audioRef.current!.currentTime!=(parseInt(localStorage.getItem(`${data.questionCode}-audio`)??"0")-20))
                audioRef.current!.currentTime=parseInt(localStorage.getItem(`${data.questionCode}-audio`)??"0")-20;
            }}
            controls
            controlsList="nodownload noplaybackrate"
            className="w-full" 
            preload="auto"
            style={{pointerEvents: data.audioMaxReplays==0 || (attempt<data.audioMaxReplays && !playing)?"all":"none"}}
            onPlay={()=>{
                document.getElementsByTagName("button")[0].focus();
                setCounterTimer(setInterval((e:number) => {
                    localStorage.setItem(`${data.questionCode}-audio`,`${parseInt(localStorage.getItem(`${data.questionCode}-audio`)??"0")+1}`);
                }, 1100));
                if(!localStorage.getItem(`${data.questionCode}-audio`)) {
                    onAnswer({maxRetry:attempt+1,questionCode:data.questionCode});
                    setAttempt(attempt+1);
                }else{
                    onAnswer({maxRetry:attempt,questionCode:data.questionCode});
                }
                setPlaying(true);
            }}
            // onTimeUpdate={(e)=>{
            //     if(coutner != Math.floor(e.timeStamp/1000)){
            //         setCoutner(Math.floor(e.timeStamp/1000));
            //         localStorage.setItem(`${data.questionCode}-audio`,`${parseInt(localStorage.getItem(`${data.questionCode}-audio`)??"0")+1}`);
            //     }
            // }}
            onPause={(e)=>{
                clearInterval(counterTimer)
            }}
            onReset={(e)=>{
                clearInterval(counterTimer)
            }}
            onAbort={(e)=>{
                clearInterval(counterTimer)
            }}
            onEnded={()=>{
                localStorage.removeItem(`${data.questionCode}-audio`);
                setPlaying(false);
                clearInterval(counterTimer)
            }}
        > {file &&  <source key={file} src={audioAssetUrl(file)} type="audio/mpeg" />}
        </audio>
        {
            data.audioMaxReplays!=null && data.audioMaxReplays>0 && 
            <p className="mt-3 ml-3 text-sm text-gray-500">{t(`remaining_attempt${(data.audioMaxReplays-attempt)>1?'s':''}`,{count:t(`${data.audioMaxReplays-attempt}`)})}</p> 
        }
        <p></p>
    </React.Fragment>;
}
const PDFHandler = ({file,data,onAnswer}:audioHandlerOption) => {
    return <object className="pdfView" id={file} data={`https://docs.google.com/gview?embedded=true&url=${pdfAssetUrl(file)}`}>
        <p className="mt-[30%] font-black" style={{textAlignLast:'center'}}>PDF is loading...</p>
    </object>;
}

const MediaHandler = (data:Item3,onAnswer:Function) => {
    const parseMedia = (regexData:any) => {
        switch (regexData[0]) {
            case "pdf":
                return <PDFHandler file={regexData[1]} data={data} onAnswer={(args:any)=>{}}></PDFHandler>
            case "img":
                return <img src={imgAssetUrl(regexData[1])} alt="Question..."/>;
            case "image":
                return <img src={imgAssetUrl(regexData[1])} alt="Question..."/>;
            case "audio":
                return <AudioHandler file={regexData[1]} data={data} onAnswer={(args:any)=>{onAnswer(args)}}></AudioHandler>
            default:
                return <p>{`Unhandeled pattern found >> ${regexData.join("-")}`}</p>;
        }
    }
    return data.question.split(mediaPattern).map((part:any, index:any) => 
        mediaPattern.test(part)? parseMedia(part.replace(/[{,}]/g,"").split("/"))
        :<span dangerouslySetInnerHTML={{__html:part}}></span>
    );
}

export default MediaHandler;
export { assetUrl, audioAssetUrl, imgAssetUrl, MediaHandler, pdfAssetUrl };
