import React, {
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { AnswerModel } from '../../model/answer_model';
import { Item4 } from '../../model/test_model';

const QuestionClozeWrite = (props: any) => {
  var data: Item4 = props.data;
  const [answers, setAnswers] = useState<any>({});
  const [question, setQuestion] = useState<any>();
  const [udpate, setUdpate] = useState(0);
  const { t } = useTranslation();
  
  const sendAnswer = () =>{
    const answerModel = new AnswerModel({
      questionCode: data.questionCode,
      isMarked: data.lastQuestionInSection?
      Object.values(answers).some((e:any) => e['extraParam'] === "true"):
      Object.values(answers).every((e:any) => e['extraParam'] === "true" || e['answerCode'] !== ""),
      isAnswered: Object.values(answers).every((e:any) => e['answerCode'] !== ""),
      answerData: {subData: Object.values(answers)}
    });
    props.onAnswer(answerModel);
  }

  useEffect(()=>{
    sendAnswer();
    let unformmatedQuestion = data.question.split(/({{{cloze\/.{1,10}}}})/g).map((part:any, index:any) => {
      if((/{{{cloze\/.{1,10}}}}/g).test(part)){
        let parts = part.replace(/[{,}]/g,"").split("/")
        if(answers[`cloze${parts[1]}`]==undefined){
          answers[`cloze${parts[1]}`] = {
            position: null,
            questionCode: `cloze${parts[1]}`,
            answerCode: "",
            extraParam: "false",
          };
        }

        return <span>
          <input onInput={(e:any)=>{
            answers[`cloze${parts[1]}`]['answerCode'] = e.target.value.trimStart(); 
            setAnswers(answers);
            setUdpate(Date.now());
          }} 
          value={answers[`cloze${parts[1]}`]['answerCode']} 
          className='ml-4 border-b border-black focus:border-blue-500 outline-none'></input> 
          <span onClick={(e:any)=>{
            answers[`cloze${parts[1]}`]['extraParam'] = `${answers[`cloze${parts[1]}`]['extraParam']!="true"}`;
            setAnswers(answers);
            setUdpate(Date.now());
          }} id={"b-cloze"+parts[1]} className={`markBtn px-2 py-1 text-sm bg-blue-800 text-white w-14 text-center rounded-md ${answers[`cloze${parts[1]}`]['extraParam']!=="true"?"bg-orange-400":"bg-blue-800"}`}>
            {answers[`cloze${parts[1]}`]['extraParam']=="true" ? t("unmark") : t("mark")}
          </span>
        </span>;
      }
      return <span dangerouslySetInnerHTML={{__html:part}}></span>
    });
    setQuestion(unformmatedQuestion);
  },[udpate])

  useEffect(() => {
    if(data['answer'] && data['answer']['answerData'] && data['answer']['answerData']['subData']){
      data['answer']['answerData']['subData'].forEach((answerItem:any) => {
        answers[answerItem['questionCode']] = answerItem;
      });
      setAnswers(answers);
      setUdpate(Date.now());
    } 
  }, []);

  return (
    <div>
      {data["showPosition"] && data.questionNumber && <span className="inline mt-1">{data.questionNumber}.&nbsp;</span>}
      {question}
    </div>
  );
};

export default QuestionClozeWrite;
