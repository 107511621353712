import 'react-quill/dist/quill.snow.css';

import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context_provider/user_provider';
import { AnswerModel } from '../../model/answer_model';
import { ErrorModel } from '../../model/error_model';
import QuestionHandler from '../question_handler/question_handler';

const QuestionPassageMultiple = (props: any) => {
  const { t } = useTranslation();
  const ctx: any = useContext(UserContext);
  const [answers, setAnswers] = useState<any>({});
  const [error, setError] = useState(false);
  const [force, setForce] = useState(Date.now());
  var data: any = props.data;

  useEffect(() => {
    let answerList: any = {};
    if (props.data.answer && props.data.answer.answerData  && props.data.answer.answerData.subData) {
      props.data.answer.answerData.subData.forEach((answer: any, index: number) => {
        answerList[answer['questionCode']] = new AnswerModel({
          isMarked: answer['extraParam']==="true",
          answerId: answer['answerCode'],
          questionCode: answer['questionCode'],
        });
      });
      props.data.subQuestions.forEach((question: any, index: number) => {
        props.data.subQuestions[index].item['answer'] = answerList[props.data.subQuestions[index]['code']];
      })
      setAnswers(answerList)
      setForce(Date.now());
    }
  }, []);

  const submitData = (answerList: any) => {
    let answerModel = new AnswerModel({questionCode: data.questionCode});
    let subData = Object.values(answerList).filter((answer:any)=>answer["isMarked"] || answer["answerId"]).map((answer: any) => ({ "questionCode": answer["questionCode"], "answerCode": answer["answerId"], "extraParam":answer["isMarked"] }));
    if(subData.length>0){
      answerModel.answerData = {subData: subData}
    }else{
      answerModel.answerData = {}
    }
    if (props.data['subQuestions'] && subData.length === props.data['subQuestions'].length) {
      answerModel.isAnswered=true;
    } else {
      answerModel.isAnswered=false;
    }
    answerModel.isMarked=Object.values(answerList).filter((answer:any)=>answer["isMarked"]).length>0;
    props.onAnswer(answerModel);
    setAnswers(answerList);
  }

  return (
    <div key={`F${force}`} className="md:flex gap-10">
      <div className="md:w-[50vw] text-justify md:border md:p-5 overflow-y-auto ">
        {data.renderedQuestion}
      </div>
      {/* md:max-h-[55vh] */}
      <div className="md:w-[50vw] md:border md:p-5 overflow-y-auto md:max-h-[55vh]">
        {props.data.subQuestions.map((item: any,index:number) => {
            return <div className="mb-4" key={`KEY-${item.code}`}>
              <div className="mt:14 mt-8 md:mt-2">
                <div>
                  <QuestionHandler
                    onError={(error:ErrorModel)=>{
                      console.log(error);
                    }} 
                    onAnswer={(answer: AnswerModel) => {
                      debugger;
                      let answerList: any = answers;
                      if (answer && answer.questionCode) {
                        if(answerList[item.code] && answerList[item.code].isMarked){
                          answer.isMarked = answerList[item.code].isMarked;
                        }
                        answerList[item.code] = answer;
                      } else {
                        delete (answerList[item.code]);
                      }
                      submitData(answerList);
                    }}
                    data={{...item.item,"questionNumber":index+props.data['customPosition'],showPosition:true}}
                  ></QuestionHandler>
                  {ctx.test.markItemEnabled ? (
                  <button 
                    className={`${!(answers[item.code] && answers[item.code]['isMarked']==true)? "bg-orange-400":"bg-blue-800"} my-2 bg-blue-800 text-white p-0.5 px-2 rounded-md text-sm`}
                    onClick={() => {
                      debugger;
                      let answerList: any = answers;
                      let answer:AnswerModel = new AnswerModel();
                      if(answerList[item.code]){
                        answer = answerList[item.code];
                      }else{
                        answer.questionCode = item.code;
                      }
                      answer.isMarked=!(answer.isMarked);
                      answerList[item.code] = answer;
                      submitData(answerList);
                    }}>
                    {answers[item.code] && answers[item.code]['isMarked'] ? t("unmark") : t("mark")}
                  </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {error && (answers === undefined || !answers[item.code]) && <p className="text-red-500">
                <small>This field is required*</small></p>}
            </div>
          })}
      </div>
    </div>
  );
};

export default QuestionPassageMultiple;
