enum routingPath {
    start = '/',
    notFound = "/not_found",
    dpa = "/dpa",

    tutorialInstruction = "/tuins",
    tutorialQuestion = "/tuqus",
    tutorialFinish = "/tufin",

    demographicInstruction = "/demins",
    demographicQuestion = "/demqus",
    demographicFinish = "/demfin",

    testTakingInstruction = "/ttins",
    testTakingQuestion = "/ttqus",
    testTakingQuestionList = "/ttquslist",
    testTakingFinish = "/ttfin",
    TestTakingTimeOut = "/ttout",

    verificationUser = "/verifyYou",
    verificationDocs = "/verifyDoc",
 
    testFinish = "/finish",
    testNotFound = "/no_test",
}
export default routingPath;