import { useState } from 'react';

import { Item4 } from '../../model/test_model';

const QuestionMultipleArticle = (props:any) => {
    const [selectedOptions, setSelectedOptions] = useState<Record<number, string>>({});

    var data:Item4 = props.data;

    const handleOptionSelect = (questionId: number, option: string) => {
        setSelectedOptions((prevOptions: Record<number, string>) => ({
            ...prevOptions,
            [questionId]: option,
        }));
    };

    return (
        <div>
            <div className="article text-gray-700 text-md">
                {data.map((para) => (
                    <p key={data.id}>
                        <span className="paragraph-number">{data.idPara}</span> {data.article}
                    </p>
                ))}
            </div>
            <div className="mcq-questions mt-4 flex justify-between">
                {data.map((question) => (
                    <div key={question.id}>
                        <p className="mb-2">
                            <span className="question-number">Q{question.id}.</span>{" "}
                            {question.question}
                        </p>
                        <ul>
                            {data.options2.map((option2, index) => (
                                <li key={index}>
                                    <input
                                        type="radio"
                                        id={`question-${data.id}-option-${index}`}
                                        name={`question-${data.id}`}
                                        className="p-1 my-2"
                                        checked={selectedOptions[data.id] === option2}
                                        onChange={() => handleOptionSelect(data.id, option2)}
                                    />
                                    <label className="pl-2 text-sm" htmlFor={`question-${question.id}-option-${index}`}>
                                        {option2}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QuestionMultipleArticle;
