import {
  useContext,
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Qtimer from '../../../components/common/question_timer';
import QuestionHandler
  from '../../../components/question_handler/question_handler';
import { UserContext } from '../../../context_provider/user_provider';
import { AnswerModel } from '../../../model/answer_model';
import { ErrorModel } from '../../../model/error_model';
import routingPath from '../../../routing/router_path';

function TutorialQuestion() {
    const navigate = useNavigate();
    const ctx:any = useContext(UserContext);
    const [answer, setAnswer] = useState<AnswerModel>(new AnswerModel())
    const [insInfo, setInsInfo] = useState(0)
    const { t, i18n } = useTranslation();
    const [isNextEnabled, setIsNextEnabled] = useState(false);

    useEffect(()=>{
        if(ctx.test['tutorialSections'] && ctx.test['tutorialSections'][ctx.Qsection]['items'][ctx.Qposition]['answer']){
            setAnswer(ctx.test['tutorialSections'][ctx.Qsection]['items'][ctx.Qposition]['answer']);
        }
    },[answer]);


    const nextQuestion=()=>{
        setInsInfo(3);
        ctx.setQPosition(ctx.Qposition+1);
        setAnswer(new AnswerModel());
        setIsNextEnabled(true);
    }

    function finishTutorial(event:any):void {
        navigate(routingPath.tutorialFinish);
    }

    const prevQuestion=()=>{
        ctx.setQPosition(ctx.Qposition-1);
        setAnswer(new AnswerModel());
    }
    
    return (!ctx.test["tutorialSections"])?<></>:(
    <div className="h-fit webkit-center">
        <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
            <div className="max-w-full flex flex-wrap items-center justify-between mx-auto p-4">
                <img src="/images/nxbas.avif" className="h-14" alt="NxBAS Logo" /> 
                <div className="sm:flex" style={{"alignItems":"baseline","textAlignLast":"center"}}>
                    <p className="text-sm sm:mr-6 uppercase">ID : {ctx.user['username']}</p>
                    <Qtimer 
                    onFinish={()=>{finishTutorial(undefined);}}
                    onLow={()=>{}}
                    highlight={insInfo === 0 && !isNextEnabled ? "tutorial" : ""}
                    seconds={ctx.test.tutorialSections[0]['timeLimit']}
                    ></Qtimer>
                </div> 
            </div>
        </nav>
        <hr/><br/>
        <div className="mr-5 md:mr-40" style={{"textAlignLast":"end"}}>
            <button onClick={()=>{ 
                navigate(routingPath.tutorialFinish)
            }} className="font-normal text-xs sm:mr-[5rem] text-white border bg-blue-500 hover:bg-blue-300  border-blue-500 py-2 px-3 rounded-md">{t("skip_ttl")}</button>
        </div>
        <div 
            key={`QKEY${ctx.Qposition}`} 
            className='text-left m-5 md:m-10 block overflow-y-auto max-w-8xl' style={{"height":"70vh"}}>
            {
                (insInfo===0)?
                <div className="text-left text-sm p-3 bg-[#b2d8d8] rounded-md mb-5">
                    <p>{ctx.t("tut_instruction_1")}{t("hourglass")} <span data-v-6a863c08="" className="hour_glass"></span>  {t("3_min_left")}</p>
                    <button
                        className="mt-3 disabled:bg-gray-300 bg-[#008080] text-white p-2 px-6 rounded-md" 
                        onClick={()=>{setInsInfo(1)}}>{ctx.t("next")}</button>
                </div>:<></>
            }
            <p className="text-3xl text-left">{ctx.t("tutorial_example_qus")}</p>
            <p className="my-3 text-left">{ctx.Qposition+1} {ctx.t("out_of")} {ctx.test['tutorialSections'][ctx.Qsection]['items'].length}</p> 
            <br/> 
            
            <QuestionHandler 
                onError={(error:ErrorModel)=>{
                    console.log(error);
                }} 
                onAnswer={(answer:AnswerModel)=>{
                    var testQuestion = ctx.test;
                    if(insInfo===1) setInsInfo(2);
                    testQuestion['tutorialSections'][ctx.Qsection]['items'][ctx.Qposition]['answer']=answer;
                    ctx.setTest(testQuestion);
                    setAnswer(answer);
                }}
                highlight={insInfo===1?"tutorial":""}
                data={ctx.test['tutorialSections'][ctx.Qsection]['items'][ctx.Qposition]}
            ></QuestionHandler>
            <div className='mt-10 w-full text-center  mb-20'>
                <button 
                    disabled={ctx.Qposition<=0}
                    className="mx-2 disabled:bg-gray-300 bg-gray-600 text-white p-2 px-6 rounded-md" 
                    onClick={()=>{prevQuestion()}}>{ctx.t("prev")}</button>
                {
                    (ctx.Qposition+1)===(ctx.test['tutorialSections'][ctx.Qsection]['items'].length)?
                    <button 
                        disabled={answer && answer.questionCode==null}
                        className="mx-2 disabled:bg-gray-300 bg-blue-500 text-white p-2 px-6 rounded-md" 
                        onClick={()=>{finishTutorial('')}}>{ctx.t("finish")}</button>:
                    <button 
                        disabled={answer && answer.questionCode==null}
                        className={`mx-2 disabled:bg-gray-300 bg-blue-500 text-white p-2 px-6 rounded-md ${insInfo===2?'shadow_animation':''}`} 
                        onClick={()=>{nextQuestion()}}>{ctx.t("next")}</button>
                }
            </div>
        </div>
    </div>
    );
}

export default TutorialQuestion;
